/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = () => `Subdl: Kostenlose Untertitel für Filme & TV-Serien herunterladen | Über 100 Sprachen | Sofortiger Zugriff`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = () => `Kostenlose Untertitel für Filme und TV-Serien in mehreren Sprachen auf Subdl.com herunterladen. Finden Sie und erhalten Sie Untertitel für ganze Serien-Stadien von verschiedenen Quellen sofort.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = () => `Untertitel herunterladen, kostenlose Untertitel, Film-Untertitel, TV-Serien-Untertitel, mehrsprachige Untertitel, Untertitelsuche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = () => `Finden Sie perfekte Untertitel in jeder Sprache für all Ihre Lieblingsfilme und -serien!`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params) => `Suche nach ${params.query} Untertiteln - SUBDL`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params) => `Suche sofort nach ${params.query} Untertiteln auf Englisch, Arabisch, Indonesisch, Französisch, Deutsch, Spanisch und mehr.`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params) => `${params.query} Untertitel Download, ${params.query} Untertitel, ${params.query} Untertitel herunterladen, ${params.query} BluRay Untertitel, ${params.query} HDTV Untertitel, Kostenloser ${params.query} Untertitel-Download, ${params.query} Closed Captions, Offizielle ${params.query} Untertitel, ${params.query} Untertiteldatei SRT, ${params.query} Untertitel synchronisieren, ${params.query} Untertitelübersetzung, Hochwertige ${params.query} Untertitel, ${params.query} Untertitel-Download für Mobilgeräte, ${params.query} Untertitel für Hörgeschädigte, Neueste ${params.query} Untertitel-Aktualisierung`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params) => `${params.movieName} (${params.movieYear}) Untertitel - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params) => `Laden Sie sofort Untertitel für ${params.movieName} (${params.movieYear}) auf Englisch, Arabisch, Indonesisch, Französisch, Deutsch, Spanisch und mehr herunter.`


/**
 * @param {{ movieName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params) => `${params.movieName} Untertitel Download, ${params.movieName} Untertitel, ${params.movieName} Untertitel herunterladen, ${params.movieName} BluRay Untertitel, ${params.movieName} HDTV Untertitel, Kostenloser ${params.movieName} Untertitel-Download, ${params.movieName} Closed Captions, Offizielle ${params.movieName} Untertitel, ${params.movieName} Untertiteldatei SRT, ${params.movieName} Untertitel synchronisieren, ${params.movieName} Untertitelübersetzung, Hochwertige ${params.movieName} Untertitel, ${params.movieName} Untertitel-Download für Mobilgeräte, ${params.movieName} Untertitel für Hörgeschädigte, Neueste ${params.movieName} Untertitel-Aktualisierung`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params) => `${params.movieName} (${params.movieYear}) ${params.lang} Untertitel - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params) => `Laden Sie sofort Untertitel für ${params.movieName} (${params.movieYear}) auf ${params.lang} herunter.`


/**
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params) => `${params.movieName} Untertitel Download, ${params.movieName} ${params.lang} Untertitel, ${params.movieName} Untertitel ${params.lang} herunterladen, ${params.movieName} BluRay Untertitel, ${params.movieName} HDTV Untertitel, Kostenloser ${params.movieName} Untertitel-Download, ${params.movieName} Closed Captions ${params.lang}, Offizielle ${params.movieName} Untertitel, ${params.movieName} Untertiteldatei SRT, ${params.movieName} Untertitel ${params.lang} synchronisieren, ${params.movieName} Untertitelübersetzung, Hochwertige ${params.movieName} Untertitel, ${params.movieName} Untertitel-Download für Mobilgeräte, ${params.movieName} Untertitel für Hörgeschädigte, Neueste ${params.movieName} Untertitel-Aktualisierung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = () => `Startseite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = () => `Neueste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = () => `Trends`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = () => `Plugins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = () => `Blog`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = () => `Community`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = () => `Suche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = () => `Konto`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = () => `Abmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = () => `Beitreten`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = () => `Menü`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = () => `Filme und Serien suchen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = () => `Keine Ergebnisse gefunden`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params) => `Treffer (${params.count} Ergebnisse)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = () => `Sucheinstellungen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = () => `Nur Filme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = () => `Nur Serien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = () => `Sprachen filtern`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = () => `Sprachen ausgewählt`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params) => `Alle Ergebnisse für "${params.query}" anzeigen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = () => `Suchergebnisse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = () => `Keine Ergebnisse gefunden`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params) => `Treffer (${params.count} Ergebnisse)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = () => `Werbung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = () => `API-Dokumentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = () => `Site Einstellungen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = () => `Dunkles Thema`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = () => `Helles Thema`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = () => `Site Sprache`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = () => `Sprachen filtern`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = () => `Titel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = () => `Qualität`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = () => `Autor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = () => `Veröffentlichungen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = () => `Bewertung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = () => `Datum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = () => `Kommentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = () => `n_id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = () => `slug`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = () => `Link`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = () => `Wird geladen...`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params) => `${params.lang} Untertitel für ${params.movieName} (${params.movieYear}) herunterladen`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params) => `${params.lang} Untertitel für ${params.movieName} (${params.movieYear}) - ${params.seasonName} herunterladen`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params) => `Untertitel für ${params.movieName} (${params.movieYear}) - ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = () => `Release Info`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = () => `Ein Kommentar von`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = () => `Tags`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = () => `Kommentare`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = () => `Download`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params) => `Download ${params.language} Untertitel`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params) => `Download ${params.language} Untertitel`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params) => `Download ${params.language} Untertitel für Mobiltelefone`


export { subtitle_page_seasons } from "./en.js"

export { subtitle_page_season } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = () => `Bewertung Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = () => `Bewertung und Kommentare`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = () => `Kommentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = () => `Senden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = () => `Schließen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = () => `Veröffentlicht am`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = () => `Bewertung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = () => `Hörgeschädigt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = () => `Kommentar senden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = () => `Subdl Untertiteler / Übersetzer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = () => `Bewertung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = () => `Kommentare`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = () => `Neueste Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = () => `Angepinnte Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = () => `Mehr Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = () => `Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = () => `Download`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = () => `Hörgeschädigt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = () => `Kommentar senden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = () => `Seite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = () => `Von Jan 1st 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = () => `Basierend auf Benutzerbewertungen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = () => `Nächste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = () => `Vorherige`


/**
 * @param {{ lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params) => `Neueste ${params.lang} Untertitel - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = () => `Neueste Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = () => `Film`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = () => `Autor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = () => `Zeit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = () => `Eine Liste mit neuesten Untertiteln.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = () => `Staffel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = () => `Benutzernavigation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = () => `Dashboard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = () => `Untertitel hochladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = () => `Meine Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = () => `Profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = () => `Abmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = () => `Dashboard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = () => `Untertitel hochladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = () => `Laden Sie Ihre Untertitel in einem Klick hoch.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = () => `Meine Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = () => `Verwalten Sie Ihre Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = () => `Verwalten und erhalten Sie den API-Schlüssel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = () => `Profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = () => `Aktualisieren Sie Ihr Profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = () => `Untertitel hochladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = () => `Finde Film von`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = () => `Film/Serie auswählen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = () => `Untertitel-Dateien hierher ziehen oder klicken, um auszuwählen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = () => `Dateien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = () => `Releases`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = () => `Hinzufügen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = () => `Entfernen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = () => `Sprache`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = () => `Sprache auswählen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = () => `Release Typ (Qualität)`


export { upload_page_select_quality } from "./en.js"

export { upload_page_production_type } from "./en.js"

export { upload_page_select_production_type } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = () => `Entfernen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = () => `Transkript (durch Hören)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = () => `Übersetzter Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = () => `Verbesserter Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = () => `Aus dem Einzelhandel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = () => `Maschinensprachiger Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = () => `Staffel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = () => `Folge`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = () => `Vollständige Staffel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = () => `Kommentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = () => `Hörgeschädigt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = () => `FPS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = () => `Hochladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = () => `nicht bekannt`


export { edit_subtitle_page_title } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = () => `Schnelles Herunterladen`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params) => `Staffel ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = () => `Anmelden - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = () => `Trete unserer Community bei und starte herunterladen und hochladen von Untertiteln in deinen Lieblingssprachen.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = () => `Anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = () => `Gib deine Informationen ein, um ein Konto zu erstellen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = () => `E-Mail`


export { register_page_username } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = () => `Passwort`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = () => `Land`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = () => `Konto erstellen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = () => `Mit Google anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = () => `Hast du bereits ein Konto?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = () => `Anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = () => `Anmelden - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = () => `Melde dich mit deiner E-Mail und deinem Passwort an`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = () => `Anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = () => `Gib deine Informationen ein, um dich anzumelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = () => `Anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = () => `Mit Google anmelden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = () => `Hast du noch kein Konto?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = () => `Registrieren`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = () => `Passwort vergessen?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = () => `Seite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = () => `Abgemeldet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = () => `Du wurdest erfolgreich abgemeldet.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = () => `Suche...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = () => `Geben Sie den Text ein, drücken Sie Enter oder klicken Sie auf das Symbol`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = () => `Meine Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = () => `Verwalten Sie meine Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = () => `Untertitel hinzufügen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle = () => `Untertitel bearbeiten`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = () => `Seite nicht gefunden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = () => `Seite nicht gefunden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = () => `Zur Startseite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = () => `Etwas ist schief gelaufen!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = () => `Etwas ist schief gelaufen!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = () => `Zur Startseite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = () => `Keine Untertitel gefunden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = () => `Sie können Untertitel für diese Seite hochladen, wenn Sie eine haben.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = () => `Untertitel hochladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = () => `Verwalten und erhalten Sie den API-Schlüssel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = () => `API-Dokumentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = () => `Untertitel-Such-API-Dokumentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = () => `Plugins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = () => `Plugins für Subdl.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = () => `Letzte Suche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = () => `Löschen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = () => `Trends`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = () => `Die Biografie muss mindestens 10 Zeichen lang sein.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = () => `Die Biografie darf nicht länger als 500 Zeichen sein.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = () => `Der Kommentar muss mindestens 5 Zeichen lang sein.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = () => `Der Kommentar darf nicht länger als 500 Zeichen sein.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = () => `Bitte melden Sie sich zuerst an`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = () => `Bitte geben Sie einen Kommentar ein`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = () => `Kommentar gesendet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = () => `Ihr Kommentar wurde eingereicht`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = () => `Ihr Kommentar wurde erfolgreich eingereicht`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = () => `Hinterlassen Sie einen Kommentar zu diesem Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = () => `Kommentar absenden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = () => `Bewertung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = () => `Hochgeladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = () => `Veröffentlicht`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = () => `Meine Untertitel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = () => `Liste der ausstehenden genehmigten Untertitel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = () => `Sind Sie sicher, dass Sie diesen Untertitel löschen möchten?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = () => `Untertitel erfolgreich gelöscht.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = () => `ID`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = () => `Sprache`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = () => `Datum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = () => `Aktion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = () => `Ausstehend`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = () => `Veröffentlicht`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = () => `Abgelehnt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = () => `Löschen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = () => `Bearbeiten`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = () => `Erneut senden`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = () => `Sind Sie sicher, dass Sie eine Bot-Nachricht an Telegram senden möchten?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = () => `Film`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = () => `Sprache`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = () => `HI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = () => `Datum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = () => `Herunterladen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = () => `Aktionen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = () => `An Kanal senden`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params) => `Untertitel bearbeiten ${params.name}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_submit = () => `Aktualisieren`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = () => `Meine öffentliche Seite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = () => `Sehen Sie Ihre öffentliche Seite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_all = () => `Alle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = () => `Passwort bestätigen`
