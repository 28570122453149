/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = () => `Subdl: फिल्मों और टीवी शो के लिए मुफ्त उपशीर्षक डाउनलोड करें | 100+ भाषाएँ | तत्काल पहुंच`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = () => `Subdl.com पर कई भाषाओं में फिल्मों और टीवी शो के लिए मुफ्त उपशीर्षक डाउनलोड करें। विभिन्न स्रोतों से पूरे सीरीज सीज़न के लिए तुरंत उपशीर्षक खोजें और प्राप्त करें।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = () => `उपशीर्षक डाउनलोड, मुफ्त उपशीर्षक, फिल्म उपशीर्षक, टीवी शो उपशीर्षक, बहुभाषी उपशीर्षक, उपशीर्षक खोज`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = () => `अपनी सभी पसंदीदा फिल्मों और शो के लिए किसी भी भाषा में सही उपशीर्षक खोजें!`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params) => `${params.query} उपशीर्षक खोजें - SUBDL`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params) => `अंग्रेजी, अरबी, इंडोनेशियाई, फ्रेंच, जर्मन, स्पेनिश और अधिक में ${params.query} उपशीर्षक तुरंत खोजें।`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params) => `${params.query} उपशीर्षक डाउनलोड, ${params.query} उपशीर्षक, ${params.query} सब्स डाउनलोड करें, ${params.query} ब्लू-रे उपशीर्षक, ${params.query} HDTV उपशीर्षक, मुफ्त ${params.query} उपशीर्षक डाउनलोड, ${params.query} बंद कैप्शन, आधिकारिक ${params.query} उपशीर्षक, ${params.query} उपशीर्षक फ़ाइल SRT, ${params.query} उपशीर्षक सिंक करें, ${params.query} उपशीर्षक अनुवाद, उच्च-गुणवत्ता वाले ${params.query} उपशीर्षक, मोबाइल के लिए ${params.query} उपशीर्षक डाउनलोड, श्रवण बाधित लोगों के लिए ${params.query} उपशीर्षक, नवीनतम ${params.query} उपशीर्षक अपडेट`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params) => `${params.movieName} (${params.movieYear}) उपशीर्षक - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params) => `अंग्रेजी, अरबी, इंडोनेशियाई, फ्रेंच, जर्मन, स्पेनिश और अधिक में ${params.movieName} (${params.movieYear}) के लिए उपशीर्षक तुरंत डाउनलोड करें।`


/**
 * @param {{ movieName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params) => `${params.movieName} उपशीर्षक डाउनलोड, ${params.movieName} उपशीर्षक, ${params.movieName} सब्स डाउनलोड करें, ${params.movieName} ब्लू-रे उपशीर्षक, ${params.movieName} HDTV उपशीर्षक, मुफ्त ${params.movieName} उपशीर्षक डाउनलोड, ${params.movieName} बंद कैप्शन, आधिकारिक ${params.movieName} उपशीर्षक, ${params.movieName} उपशीर्षक फ़ाइल SRT, ${params.movieName} उपशीर्षक सिंक करें, ${params.movieName} उपशीर्षक अनुवाद, उच्च-गुणवत्ता वाले ${params.movieName} उपशीर्षक, मोबाइल के लिए ${params.movieName} उपशीर्षक डाउनलोड, श्रवण बाधित लोगों के लिए ${params.movieName} उपशीर्षक, नवीनतम ${params.movieName} उपशीर्षक अपडेट`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params) => `${params.movieName} (${params.movieYear}) ${params.lang} उपशीर्षक - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params) => `${params.movieName} (${params.movieYear}) के लिए ${params.lang} में उपशीर्षक तुरंत डाउनलोड करें।`


/**
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params) => `${params.movieName} उपशीर्षक डाउनलोड, ${params.movieName} ${params.lang} उपशीर्षक, ${params.movieName} सब्स ${params.lang} डाउनलोड करें, ${params.movieName} ब्लू-रे उपशीर्षक, ${params.movieName} HDTV उपशीर्षक, मुफ्त ${params.movieName} उपशीर्षक डाउनलोड, ${params.movieName} बंद कैप्शन ${params.lang}, आधिकारिक ${params.movieName} उपशीर्षक, ${params.movieName} उपशीर्षक फ़ाइल SRT, ${params.movieName} उपशीर्षक ${params.lang} सिंक करें, ${params.movieName} उपशीर्षक अनुवाद, उच्च-गुणवत्ता वाले ${params.movieName} उपशीर्षक, मोबाइल के लिए ${params.movieName} उपशीर्षक डाउनलोड, श्रवण बाधित लोगों के लिए ${params.movieName} उपशीर्षक, नवीनतम ${params.movieName} उपशीर्षक अपडेट`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = () => `होम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = () => `नवीनतम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = () => `ट्रेंड्स`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = () => `प्लगइन्स`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = () => `ब्लॉग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = () => `समुदाय`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = () => `खोज`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = () => `खाता`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = () => `लॉगआउट`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = () => `शामिल हों`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = () => `मेनू`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = () => `फिल्में और टीवी शो या IMDB आईडी खोजें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = () => `कोई परिणाम नहीं मिला`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params) => `मिलान (${params.count} परिणाम)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = () => `खोज सेटिंग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = () => `केवल फिल्में`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = () => `केवल टीवी शो`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = () => `भाषाएँ फ़िल्टर करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = () => `चयनित भाषाएँ`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params) => `"${params.query}" के लिए सभी परिणाम देखें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = () => `खोज परिणाम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = () => `कोई परिणाम नहीं मिला`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params) => `मिलान (${params.count} परिणाम)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = () => `विज्ञापन`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = () => `API दस्तावेज़`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = () => `साइट सेटिंग्स`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = () => `डार्क थीम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = () => `लाइट थीम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = () => `साइट भाषा`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = () => `भाषाएँ फ़िल्टर करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = () => `शीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = () => `गुणवत्ता`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = () => `लेखक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = () => `रिलीज़`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = () => `दर`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = () => `तारीख`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = () => `टिप्पणी`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = () => `n_id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = () => `स्लग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = () => `लिंक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = () => `लोड हो रहा है...`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params) => `${params.movieName} (${params.movieYear}) के लिए ${params.lang} उपशीर्षक डाउनलोड करें`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params) => `${params.movieName} (${params.movieYear}) - ${params.seasonName} के लिए ${params.lang} उपशीर्षक डाउनलोड करें`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params) => `${params.movieName} (${params.movieYear}) - ${params.seasonNumber} के लिए उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = () => `रिलीज़ जानकारी`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = () => `टिप्पणी द्वारा`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = () => `टैग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = () => `टिप्पणियाँ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = () => `डाउनलोड`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params) => `${params.language} उपशीर्षक डाउनलोड करें`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params) => `${params.language} उपशीर्षक डाउनलोड करें`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params) => `मोबाइल के लिए ${params.language} उपशीर्षक डाउनलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_seasons = () => `सीज़न`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_season = (params) => `सीज़न ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = () => `उपशीर्षक को रेट करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = () => `इस उपशीर्षक पर रेट और टिप्पणी करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = () => `टिप्पणी`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = () => `भेजें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = () => `बंद करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = () => `प्रकाशित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = () => `रेट`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = () => `डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = () => `श्रवण बाधित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = () => `टिप्पणी सबमिट करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = () => `Subdl लेखक / अनुवादक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = () => `रेटिंग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = () => `डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = () => `टिप्पणियाँ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = () => `नवीनतम उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = () => `पिन किए गए उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = () => `अधिक उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = () => `उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = () => `डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = () => `श्रवण बाधित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = () => `टिप्पणी सबमिट करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = () => `पेज`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = () => `1 जनवरी 2024 से`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = () => `उपयोगकर्ता रेटिंग के आधार पर`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = () => `अगला`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = () => `पिछला`


/**
 * @param {{ lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params) => `नवीनतम ${params.lang} उपशीर्षक - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = () => `नवीनतम उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = () => `फिल्म`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = () => `नाम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = () => `लेखक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = () => `डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = () => `समय`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = () => `आपके हाल के उपशीर्षकों की एक सूची।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = () => `सीज़न`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = () => `उपयोगकर्ता नेव`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = () => `डैशबोर्ड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = () => `उपशीर्षक अपलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = () => `मेरे उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = () => `प्रोफ़ाइल`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = () => `लॉगआउट`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = () => `डैशबोर्ड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = () => `उपशीर्षक अपलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = () => `एक क्लिक में अपने उपशीर्षक अपलोड करें।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = () => `मेरे उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = () => `अपने उपशीर्षकों का प्रबंधन करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = () => `API कुंजी प्रबंधित करें और प्राप्त करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = () => `प्रोफ़ाइल`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = () => `अपनी प्रोफ़ाइल अपडेट करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = () => `उपशीर्षक अपलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = () => `फिल्म यहाँ से खोजें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = () => `फिल्म/टीवी चुनें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = () => `उपशीर्षक फ़ाइलें यहाँ छोड़ें या चयन करने के लिए क्लिक करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = () => `फ़ाइलें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = () => `रिलीज़`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = () => `जोड़ें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = () => `हटाएँ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = () => `भाषा`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = () => `भाषा चुनें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = () => `नाम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = () => `रिलीज़ प्रकार (गुणवत्ता)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_quality = () => `गुणवत्ता चुनें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_production_type = () => `उत्पादन प्रकार`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_production_type = () => `उत्पादन प्रकार चुनें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = () => `हटाएँ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = () => `प्रतिलेख (सुनकर)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = () => `उपशीर्षक का अनुवाद किया गया`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = () => `उपशीर्षक में सुधार किया गया`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = () => `रिटेल से`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = () => `मशीन द्वारा अनुवादित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = () => `सीज़न`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = () => `एपिसोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = () => `पूरा सीज़न`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = () => `टिप्पणी`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = () => `श्रवण बाधित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = () => `FPS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = () => `अपलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = () => `पता नहीं`


export { edit_subtitle_page_title } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = () => `त्वरित डाउनलोड`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params) => `सीज़न ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = () => `पंजीकृत - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = () => `हमारे समुदाय में शामिल हो जाएं और अपने पसंदीदा भाषाओं में उपशीर्षक डाउनलोड और अपलोड करें।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = () => `पंजीकृत`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = () => `खाते बनाने के लिए आपकी जानकारी दर्ज करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = () => `ईमेल`


export { register_page_username } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = () => `पासवर्ड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = () => `देश`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = () => `खाता बनाएं`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = () => `गूगल के साथ साइन अप करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = () => `आपके पास एक खाता हैं?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = () => `साइन इन`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = () => `लॉगिन - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = () => `अपने ईमेल और पासवर्ड के साथ लॉगिन करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = () => `लॉगिन`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = () => `अपने जानकारी दर्ज करें लॉगिन करने के लिए`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = () => `लॉगिन`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = () => `गूगल के साथ लॉगिन करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = () => `आपके पास एक खाता हैं?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = () => `साइन अप करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = () => `पासवर्ड भूल गए हैं?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = () => `पृष्ठ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = () => `लॉग आउट`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = () => `आपने सफलतापूर्वक लॉग आउट किया है.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = () => `खोज...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = () => `पाठ दर्ज करें, पूर्ण करने के लिए एंड दबाएं या आइकन पर क्लिक करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = () => `मेरे उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = () => `मेरे उपशीर्षक प्रबंधित करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = () => `उपशीर्षक जोड़ें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle = () => `उपशीर्षक संपादित करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = () => `पृष्ठ नहीं मिला`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = () => `पृष्ठ नहीं मिला`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = () => `घर पर जाएं`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = () => `कुछ गलत हो गया!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = () => `कुछ गलत हो गया!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = () => `घर पर जाएं`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = () => `कोई उपशीर्षक नहीं मिला`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = () => `यदि आपके पास एक है तो आप इस पृष्ठ के लिए उपशीर्षक अपलोड कर सकते हैं।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = () => `उपशीर्षक अपलोड करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = () => `एक्सटेंशन की जानकारी और एक्सटेंशन की गुण प्राप्त करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = () => `API कोड के लिए डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = () => `उपशीर्षक खोज API कोड के लिए डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = () => `प्लगइन्स`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = () => `Subdl.com के लिए प्लगइन`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = () => `हाल के लिए खोज`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = () => `साफ़ करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = () => `ट्रेंड्स`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = () => `बायो कम से कम 10 अक्षर का होना चाहिए।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = () => `बायो 500 अक्षरों से अधिक नहीं होना चाहिए।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = () => `टिप्पणी कम से कम 5 अक्षर की होनी चाहिए।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = () => `टिप्पणी 500 अक्षरों से अधिक नहीं होनी चाहिए।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = () => `कृपया पहले लॉगिन करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = () => `कृपया टिप्पणी दर्ज करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = () => `टिप्पणी भेजी गई`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = () => `आपकी टिप्पणी जमा की गई`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = () => `आपकी टिप्पणी सफलतापूर्वक जमा की गई है`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = () => `इस उपशीर्षक के बारे में टिप्पणी छोड़ें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = () => `टिप्पणी जमा करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = () => `रेटिंग`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = () => `अपलोड किए गए`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = () => `प्रकाशित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = () => `मेरे उपशीर्षक`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = () => `स्वीकृति के लिए लंबित उपशीर्षकों की सूची।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = () => `क्या आप वाकई इस उपशीर्षक को हटाना चाहते हैं?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = () => `उपशीर्षक सफलतापूर्वक हटा दिया गया।`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = () => `आईडी`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = () => `नाम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = () => `भाषा`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = () => `तिथि`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = () => `स्थिति`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = () => `कार्रवाई`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = () => `लंबित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = () => `प्रकाशित`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = () => `अस्वीकृत`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = () => `हटाएं`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = () => `संपादित करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = () => `पुनः भेजें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = () => `क्या आप वाकई टेलीग्राम पर बॉट संदेश भेजना चाहते हैं?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = () => `फिल्म`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = () => `नाम`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = () => `भाषा`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = () => `HI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = () => `तिथि`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = () => `स्थिति`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = () => `डाउनलोड`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = () => `कार्रवाइयाँ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = () => `चैनल पर भेजें`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params) => `उपशीर्षक संपादित करें ${params.name}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_submit = () => `अद्यतन करें`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = () => `मेरी सार्वजनिक पृष्ठ`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = () => `अपने सार्वजनिक पृष्ठ देखें`


export { search_setting_all } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = () => `पासवर्ड की पुष्टि करें`
