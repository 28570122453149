/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = () => `Subdl: Unduh Subtitle Gratis untuk Film & Acara TV | 100+ Bahasa | Akses Instan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = () => `Unduh subtitle gratis untuk film dan acara TV dalam berbagai bahasa di Subdl.com. Temukan dan dapatkan subtitle untuk seluruh musim serial dari berbagai sumber secara instan.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = () => `unduh subtitle, subtitle gratis, subtitle film, subtitle acara TV, subtitle multibahasa, pencarian subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = () => `Temukan subtitle sempurna dalam bahasa apa pun untuk semua film dan acara favorit Anda!`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params) => `Cari Subtitle ${params.query} - SUBDL`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params) => `Cari Subtitle ${params.query} dalam Bahasa Inggris, Arab, Indonesia, Prancis, Jerman, Spanyol, dan lainnya secara Instan.`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params) => `unduh subtitle ${params.query}, subtitle ${params.query}, Unduh sub ${params.query}, subtitle BluRay ${params.query}, subtitle HDTV ${params.query}, Unduh subtitle ${params.query} gratis, caption tertutup ${params.query}, Subtitle resmi ${params.query}, file subtitle ${params.query} SRT, Sinkronkan subtitle ${params.query}, terjemahan subtitle ${params.query}, Subtitle ${params.query} berkualitas tinggi, unduh subtitle ${params.query} untuk ponsel, subtitle ${params.query} untuk tuna rungu, Pembaruan subtitle ${params.query} terbaru`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params) => `Subtitle ${params.movieName} (${params.movieYear}) - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params) => `Unduh subtitle untuk ${params.movieName} (${params.movieYear}) dalam Bahasa Inggris, Arab, Indonesia, Prancis, Jerman, Spanyol, dan lainnya secara Instan.`


/**
 * @param {{ movieName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params) => `unduh subtitle ${params.movieName}, subtitle ${params.movieName}, Unduh sub ${params.movieName}, subtitle BluRay ${params.movieName}, subtitle HDTV ${params.movieName}, Unduh subtitle ${params.movieName} gratis, caption tertutup ${params.movieName}, Subtitle resmi ${params.movieName}, file subtitle ${params.movieName} SRT, Sinkronkan subtitle ${params.movieName}, terjemahan subtitle ${params.movieName}, Subtitle ${params.movieName} berkualitas tinggi, unduh subtitle ${params.movieName} untuk ponsel, subtitle ${params.movieName} untuk tuna rungu, Pembaruan subtitle ${params.movieName} terbaru`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params) => `Subtitle ${params.movieName} (${params.movieYear}) Bahasa ${params.lang} - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params) => `Unduh subtitle untuk ${params.movieName} (${params.movieYear}) dalam Bahasa ${params.lang} secara Instan.`


/**
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params) => `unduh subtitle ${params.movieName}, subtitle ${params.movieName} Bahasa ${params.lang}, Unduh sub ${params.movieName} ${params.lang}, subtitle BluRay ${params.movieName}, subtitle HDTV ${params.movieName}, Unduh subtitle ${params.movieName} gratis, caption tertutup ${params.movieName} Bahasa ${params.lang}, Subtitle resmi ${params.movieName}, file subtitle ${params.movieName} SRT, Sinkronkan subtitle ${params.movieName} Bahasa ${params.lang}, terjemahan subtitle ${params.movieName}, Subtitle ${params.movieName} berkualitas tinggi, unduh subtitle ${params.movieName} untuk ponsel, subtitle ${params.movieName} untuk tuna rungu, Pembaruan subtitle ${params.movieName} terbaru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = () => `Beranda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = () => `Terbaru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = () => `Tren`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = () => `Plugin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = () => `Blog`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = () => `Komunitas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = () => `Cari`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = () => `Akun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = () => `Keluar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = () => `Bergabung`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = () => `Menu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = () => `Cari Film dan Acara TV atau ID IMDB`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = () => `Tidak ada hasil ditemukan`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params) => `Cocok (${params.count} hasil)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = () => `Pengaturan Pencarian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = () => `Hanya Film`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = () => `Hanya Acara TV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = () => `Filter Bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = () => `Bahasa Terpilih`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params) => `Lihat semua hasil untuk "${params.query}"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = () => `Hasil Pencarian`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = () => `Tidak ada hasil ditemukan`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params) => `Cocok (${params.count} hasil)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = () => `Iklan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = () => `Dokumentasi API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = () => `Pengaturan Situs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = () => `Tema Gelap`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = () => `Tema Terang`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = () => `Bahasa Situs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = () => `Filter Bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = () => `Judul`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = () => `Kualitas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = () => `Penulis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = () => `Rilis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = () => `Peringkat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = () => `Tanggal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = () => `Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = () => `n_id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = () => `slug`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = () => `tautan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = () => `Memuat...`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params) => `Unduh Subtitle ${params.lang} untuk ${params.movieName} (${params.movieYear})`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params) => `Unduh Subtitle ${params.lang} untuk ${params.movieName} (${params.movieYear}) - ${params.seasonName}`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params) => `Subtitle untuk ${params.movieName} (${params.movieYear}) - ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = () => `Informasi rilis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = () => `Komentar oleh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = () => `Tag`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = () => `Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = () => `Unduh`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params) => `Unduh Subtitle ${params.language}`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params) => `Unduh Subtitle ${params.language}`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params) => `Unduh Subtitle ${params.language} untuk Ponsel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_seasons = () => `Musim`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_season = (params) => `Musim ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = () => `Beri Peringkat Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = () => `Beri peringkat dan komentar untuk subtitle ini`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = () => `Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = () => `Kirim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = () => `Tutup`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = () => `Diterbitkan pada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = () => `Peringkat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = () => `Unduhan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = () => `Tuna Rungu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = () => `Kirim Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = () => `Penulis / Penerjemah Subdl`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = () => `Peringkat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = () => `Unduhan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = () => `Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = () => `Subtitle Terbaru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = () => `Subtitle Tersemat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = () => `Subtitle Lainnya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = () => `Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = () => `Unduh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = () => `Tuna Rungu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = () => `Kirim Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = () => `halaman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = () => `Sejak 1 Januari 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = () => `Berdasarkan peringkat pengguna`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = () => `Selanjutnya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = () => `Sebelumnya`


/**
 * @param {{ lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params) => `Subtitle Terbaru ${params.lang} - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = () => `Subtitle Terbaru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = () => `Film`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = () => `Nama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = () => `Penulis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = () => `Unduhan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = () => `Waktu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = () => `Daftar subtitle terbaru Anda.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = () => `Musim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = () => `Navigasi Pengguna`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = () => `Dasbor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = () => `Unggah subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = () => `Subtitle Saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = () => `Profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = () => `Keluar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = () => `Dasbor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = () => `Unggah Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = () => `Unggah subtitle Anda dengan satu klik.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = () => `Subtitle Saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = () => `Kelola subtitle Anda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = () => `Kelola dan dapatkan kunci API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = () => `Profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = () => `Perbarui profil Anda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = () => `Unggah Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = () => `Cari Film dari`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = () => `Pilih Film/TV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = () => `Letakkan File Subtitle Di Sini Atau Klik Untuk Memilih`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = () => `File`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = () => `Rilis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = () => `Tambah`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = () => `Hapus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = () => `Bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = () => `Pilih bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = () => `Nama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = () => `Jenis rilis (Kualitas)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_quality = () => `Pilih Kualitas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_production_type = () => `Jenis Produksi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_production_type = () => `Pilih Jenis Produksi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = () => `Hapus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = () => `Transkrip (Dengan mendengarkan)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = () => `Menerjemahkan subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = () => `Memperbaiki subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = () => `Dari ritel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = () => `Terjemahan mesin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = () => `Musim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = () => `Episode`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = () => `Musim Lengkap`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = () => `Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = () => `Tuna Rungu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = () => `FPS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = () => `Unggah`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = () => `tidak tahu`


export { edit_subtitle_page_title } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = () => `Unduh Cepat`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params) => `Musim ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = () => `Daftar - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = () => `Bergabunglah dengan SUBDL dan mulai mengunduh dan mengunggah subtitles Anda dalam bahasa favorit Anda.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = () => `Daftar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = () => `Masukkan informasi Anda untuk membuat akun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = () => `Email`


export { register_page_username } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = () => `Kata Sandi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = () => `Negara`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = () => `Buat Akun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = () => `Daftar dengan Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = () => `Sudah punya akun?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = () => `Masuk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = () => `Masuk - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = () => `Masuk dengan email dan kata sandi Anda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = () => `Masuk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = () => `Masukkan informasi Anda untuk masuk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = () => `Masuk`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = () => `Masuk dengan Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = () => `Tidak punya akun?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = () => `Daftar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = () => `Lupa kata sandi Anda?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = () => `Halaman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = () => `Keluar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = () => `Anda telah berhasil keluar.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = () => `Cari...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = () => `Masukkan teks, kemudian tekan Enter atau klik ikon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = () => `Subtitle Saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = () => `Kelola subtitle Saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = () => `Tambahkan subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle = () => `Edit Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = () => `Halaman tidak ditemukan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = () => `Halaman tidak ditemukan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = () => `Pergi ke beranda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = () => `Ada yang salah!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = () => `Ada yang salah!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = () => `Pergi ke beranda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = () => `Tidak ada subtitle yang ditemukan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = () => `Anda dapat mengunggah subtitle untuk halaman ini jika Anda memiliki satu.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = () => `Unggah subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = () => `Kelola dan dapatkan kunci API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = () => `Dokumentasi API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = () => `Dokumentasi API pencarian subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = () => `Plugin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = () => `Plugin untuk Subdl.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = () => `Pencarian terbaru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = () => `Hapus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = () => `Trend`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = () => `Bio harus minimal 10 karakter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = () => `Bio tidak boleh lebih dari 500 karakter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = () => `Komentar harus minimal 5 karakter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = () => `Komentar tidak boleh lebih dari 500 karakter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = () => `Silakan login terlebih dahulu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = () => `Silakan masukkan komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = () => `Komentar terkirim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = () => `Komentar Anda telah dikirim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = () => `Komentar Anda telah berhasil dikirim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = () => `Tinggalkan komentar tentang subtitle ini`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = () => `Kirim Komentar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = () => `Peringkat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = () => `Diunggah`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = () => `Dipublikasikan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = () => `Subtitle Saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = () => `Daftar subtitle yang menunggu persetujuan.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = () => `Apakah Anda yakin ingin menghapus subtitle ini?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = () => `Subtitle berhasil dihapus.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = () => `Id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = () => `Nama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = () => `Bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = () => `Tanggal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = () => `Tindakan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = () => `Tertunda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = () => `Dipublikasikan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = () => `Ditolak`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = () => `Hapus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = () => `Edit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = () => `Kirim Ulang`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = () => `Apakah Anda yakin ingin mengirim pesan bot ke Telegram?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = () => `Film`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = () => `Nama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = () => `Bahasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = () => `HI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = () => `Tanggal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = () => `Unduh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = () => `Tindakan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = () => `Kirim ke Saluran`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params) => `Edit Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_submit = () => `Perbarui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = () => `Halaman publik saya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = () => `Lihat halaman publik Anda`


export { search_setting_all } from "./en.js"

/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = () => `Konfirmasi Kata Sandi`
