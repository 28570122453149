/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as en from "./messages/en.js"
import * as fa from "./messages/fa.js"
import * as ar from "./messages/ar.js"
import * as de from "./messages/de.js"
import * as es from "./messages/es.js"
import * as hi from "./messages/hi.js"
import * as id from "./messages/id.js"
import * as pt_PT from "./messages/pt-PT.js"
import * as fr from "./messages/fr.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = (params = {}, options = {}) => {
	return {
		ar: ar.website_title,
		de: de.website_title,
		en: en.website_title,
		es: es.website_title,
		fa: fa.website_title,
		fr: fr.website_title,
		hi: hi.website_title,
		id: id.website_title,
		"pt-PT": pt_PT.website_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = (params = {}, options = {}) => {
	return {
		ar: ar.website_description,
		de: de.website_description,
		en: en.website_description,
		es: es.website_description,
		fa: fa.website_description,
		fr: fr.website_description,
		hi: hi.website_description,
		id: id.website_description,
		"pt-PT": pt_PT.website_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = (params = {}, options = {}) => {
	return {
		ar: ar.website_keywords,
		de: de.website_keywords,
		en: en.website_keywords,
		es: es.website_keywords,
		fa: fa.website_keywords,
		fr: fr.website_keywords,
		hi: hi.website_keywords,
		id: id.website_keywords,
		"pt-PT": pt_PT.website_keywords
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = (params = {}, options = {}) => {
	return {
		ar: ar.index_title,
		de: de.index_title,
		en: en.index_title,
		es: es.index_title,
		fa: fa.index_title,
		fr: fr.index_title,
		hi: hi.index_title,
		id: id.index_title,
		"pt-PT": pt_PT.index_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ query: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params , options = {}) => {
	return {
		ar: ar.search_page_title,
		de: de.search_page_title,
		en: en.search_page_title,
		es: es.search_page_title,
		fa: fa.search_page_title,
		fr: fr.search_page_title,
		hi: hi.search_page_title,
		id: id.search_page_title,
		"pt-PT": pt_PT.search_page_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ query: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params , options = {}) => {
	return {
		ar: ar.search_page_description,
		de: de.search_page_description,
		en: en.search_page_description,
		es: es.search_page_description,
		fa: fa.search_page_description,
		fr: fr.search_page_description,
		hi: hi.search_page_description,
		id: id.search_page_description,
		"pt-PT": pt_PT.search_page_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ query: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params , options = {}) => {
	return {
		ar: ar.search_page_keywords,
		de: de.search_page_keywords,
		en: en.search_page_keywords,
		es: es.search_page_keywords,
		fa: fa.search_page_keywords,
		fr: fr.search_page_keywords,
		hi: hi.search_page_keywords,
		id: id.search_page_keywords,
		"pt-PT": pt_PT.search_page_keywords
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params , options = {}) => {
	return {
		ar: ar.film_page_title,
		de: de.film_page_title,
		en: en.film_page_title,
		es: es.film_page_title,
		fa: fa.film_page_title,
		fr: fr.film_page_title,
		hi: hi.film_page_title,
		id: id.film_page_title,
		"pt-PT": pt_PT.film_page_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params , options = {}) => {
	return {
		ar: ar.film_page_description,
		de: de.film_page_description,
		en: en.film_page_description,
		es: es.film_page_description,
		fa: fa.film_page_description,
		fr: fr.film_page_description,
		hi: hi.film_page_description,
		id: id.film_page_description,
		"pt-PT": pt_PT.film_page_description
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params , options = {}) => {
	return {
		ar: ar.film_page_keywords,
		de: de.film_page_keywords,
		en: en.film_page_keywords,
		es: es.film_page_keywords,
		fa: fa.film_page_keywords,
		fr: fr.film_page_keywords,
		hi: hi.film_page_keywords,
		id: id.film_page_keywords,
		"pt-PT": pt_PT.film_page_keywords
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params , options = {}) => {
	return {
		ar: ar.film_page_title_with_lang,
		de: de.film_page_title_with_lang,
		en: en.film_page_title_with_lang,
		es: es.film_page_title_with_lang,
		fa: fa.film_page_title_with_lang,
		fr: fr.film_page_title_with_lang,
		hi: hi.film_page_title_with_lang,
		id: id.film_page_title_with_lang,
		"pt-PT": pt_PT.film_page_title_with_lang
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params , options = {}) => {
	return {
		ar: ar.film_page_description_with_lang,
		de: de.film_page_description_with_lang,
		en: en.film_page_description_with_lang,
		es: es.film_page_description_with_lang,
		fa: fa.film_page_description_with_lang,
		fr: fr.film_page_description_with_lang,
		hi: hi.film_page_description_with_lang,
		id: id.film_page_description_with_lang,
		"pt-PT": pt_PT.film_page_description_with_lang
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params , options = {}) => {
	return {
		ar: ar.film_page_keywords_with_lang,
		de: de.film_page_keywords_with_lang,
		en: en.film_page_keywords_with_lang,
		es: es.film_page_keywords_with_lang,
		fa: fa.film_page_keywords_with_lang,
		fr: fr.film_page_keywords_with_lang,
		hi: hi.film_page_keywords_with_lang,
		id: id.film_page_keywords_with_lang,
		"pt-PT": pt_PT.film_page_keywords_with_lang
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = (params = {}, options = {}) => {
	return {
		ar: ar.menu_home,
		de: de.menu_home,
		en: en.menu_home,
		es: es.menu_home,
		fa: fa.menu_home,
		fr: fr.menu_home,
		hi: hi.menu_home,
		id: id.menu_home,
		"pt-PT": pt_PT.menu_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = (params = {}, options = {}) => {
	return {
		ar: ar.menu_latest,
		de: de.menu_latest,
		en: en.menu_latest,
		es: es.menu_latest,
		fa: fa.menu_latest,
		fr: fr.menu_latest,
		hi: hi.menu_latest,
		id: id.menu_latest,
		"pt-PT": pt_PT.menu_latest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = (params = {}, options = {}) => {
	return {
		ar: ar.menu_trends,
		de: de.menu_trends,
		en: en.menu_trends,
		es: es.menu_trends,
		fa: fa.menu_trends,
		fr: fr.menu_trends,
		hi: hi.menu_trends,
		id: id.menu_trends,
		"pt-PT": pt_PT.menu_trends
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = (params = {}, options = {}) => {
	return {
		ar: ar.menu_plugins,
		de: de.menu_plugins,
		en: en.menu_plugins,
		es: es.menu_plugins,
		fa: fa.menu_plugins,
		fr: fr.menu_plugins,
		hi: hi.menu_plugins,
		id: id.menu_plugins,
		"pt-PT": pt_PT.menu_plugins
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = (params = {}, options = {}) => {
	return {
		ar: ar.menu_blog,
		de: de.menu_blog,
		en: en.menu_blog,
		es: es.menu_blog,
		fa: fa.menu_blog,
		fr: fr.menu_blog,
		hi: hi.menu_blog,
		id: id.menu_blog,
		"pt-PT": pt_PT.menu_blog
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = (params = {}, options = {}) => {
	return {
		ar: ar.menu_community,
		de: de.menu_community,
		en: en.menu_community,
		es: es.menu_community,
		fa: fa.menu_community,
		fr: fr.menu_community,
		hi: hi.menu_community,
		id: id.menu_community,
		"pt-PT": pt_PT.menu_community
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = (params = {}, options = {}) => {
	return {
		ar: ar.menu_search,
		de: de.menu_search,
		en: en.menu_search,
		es: es.menu_search,
		fa: fa.menu_search,
		fr: fr.menu_search,
		hi: hi.menu_search,
		id: id.menu_search,
		"pt-PT": pt_PT.menu_search
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = (params = {}, options = {}) => {
	return {
		ar: ar.menu_account,
		de: de.menu_account,
		en: en.menu_account,
		es: es.menu_account,
		fa: fa.menu_account,
		fr: fr.menu_account,
		hi: hi.menu_account,
		id: id.menu_account,
		"pt-PT": pt_PT.menu_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = (params = {}, options = {}) => {
	return {
		ar: ar.menu_logout,
		de: de.menu_logout,
		en: en.menu_logout,
		es: es.menu_logout,
		fa: fa.menu_logout,
		fr: fr.menu_logout,
		hi: hi.menu_logout,
		id: id.menu_logout,
		"pt-PT": pt_PT.menu_logout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = (params = {}, options = {}) => {
	return {
		ar: ar.menu_join,
		de: de.menu_join,
		en: en.menu_join,
		es: es.menu_join,
		fa: fa.menu_join,
		fr: fr.menu_join,
		hi: hi.menu_join,
		id: id.menu_join,
		"pt-PT": pt_PT.menu_join
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = (params = {}, options = {}) => {
	return {
		ar: ar.menu_menu,
		de: de.menu_menu,
		en: en.menu_menu,
		es: es.menu_menu,
		fa: fa.menu_menu,
		fr: fr.menu_menu,
		hi: hi.menu_menu,
		id: id.menu_menu,
		"pt-PT": pt_PT.menu_menu
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = (params = {}, options = {}) => {
	return {
		ar: ar.search_placeholder,
		de: de.search_placeholder,
		en: en.search_placeholder,
		es: es.search_placeholder,
		fa: fa.search_placeholder,
		fr: fr.search_placeholder,
		hi: hi.search_placeholder,
		id: id.search_placeholder,
		"pt-PT": pt_PT.search_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = (params = {}, options = {}) => {
	return {
		ar: ar.search_no_results,
		de: de.search_no_results,
		en: en.search_no_results,
		es: es.search_no_results,
		fa: fa.search_no_results,
		fr: fr.search_no_results,
		hi: hi.search_no_results,
		id: id.search_no_results,
		"pt-PT": pt_PT.search_no_results
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params , options = {}) => {
	return {
		ar: ar.search_results,
		de: de.search_results,
		en: en.search_results,
		es: es.search_results,
		fa: fa.search_results,
		fr: fr.search_results,
		hi: hi.search_results,
		id: id.search_results,
		"pt-PT": pt_PT.search_results
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = (params = {}, options = {}) => {
	return {
		ar: ar.search_settings,
		de: de.search_settings,
		en: en.search_settings,
		es: es.search_settings,
		fa: fa.search_settings,
		fr: fr.search_settings,
		hi: hi.search_settings,
		id: id.search_settings,
		"pt-PT": pt_PT.search_settings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = (params = {}, options = {}) => {
	return {
		ar: ar.search_setting_movie,
		de: de.search_setting_movie,
		en: en.search_setting_movie,
		es: es.search_setting_movie,
		fa: fa.search_setting_movie,
		fr: fr.search_setting_movie,
		hi: hi.search_setting_movie,
		id: id.search_setting_movie,
		"pt-PT": pt_PT.search_setting_movie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = (params = {}, options = {}) => {
	return {
		ar: ar.search_setting_tv,
		de: de.search_setting_tv,
		en: en.search_setting_tv,
		es: es.search_setting_tv,
		fa: fa.search_setting_tv,
		fr: fr.search_setting_tv,
		hi: hi.search_setting_tv,
		id: id.search_setting_tv,
		"pt-PT": pt_PT.search_setting_tv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = (params = {}, options = {}) => {
	return {
		ar: ar.search_setting_selected,
		de: de.search_setting_selected,
		en: en.search_setting_selected,
		es: es.search_setting_selected,
		fa: fa.search_setting_selected,
		fr: fr.search_setting_selected,
		hi: hi.search_setting_selected,
		id: id.search_setting_selected,
		"pt-PT": pt_PT.search_setting_selected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = (params = {}, options = {}) => {
	return {
		ar: ar.search_setting_has_selected_language,
		de: de.search_setting_has_selected_language,
		en: en.search_setting_has_selected_language,
		es: es.search_setting_has_selected_language,
		fa: fa.search_setting_has_selected_language,
		fr: fr.search_setting_has_selected_language,
		hi: hi.search_setting_has_selected_language,
		id: id.search_setting_has_selected_language,
		"pt-PT": pt_PT.search_setting_has_selected_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ query: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params , options = {}) => {
	return {
		ar: ar.search_other_results,
		de: de.search_other_results,
		en: en.search_other_results,
		es: es.search_other_results,
		fa: fa.search_other_results,
		fr: fr.search_other_results,
		hi: hi.search_other_results,
		id: id.search_other_results,
		"pt-PT": pt_PT.search_other_results
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = (params = {}, options = {}) => {
	return {
		ar: ar.search_page_list_title,
		de: de.search_page_list_title,
		en: en.search_page_list_title,
		es: es.search_page_list_title,
		fa: fa.search_page_list_title,
		fr: fr.search_page_list_title,
		hi: hi.search_page_list_title,
		id: id.search_page_list_title,
		"pt-PT": pt_PT.search_page_list_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = (params = {}, options = {}) => {
	return {
		ar: ar.search_page_list_no_results,
		de: de.search_page_list_no_results,
		en: en.search_page_list_no_results,
		es: es.search_page_list_no_results,
		fa: fa.search_page_list_no_results,
		fr: fr.search_page_list_no_results,
		hi: hi.search_page_list_no_results,
		id: id.search_page_list_no_results,
		"pt-PT": pt_PT.search_page_list_no_results
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ count: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params , options = {}) => {
	return {
		ar: ar.search_page_list_results,
		de: de.search_page_list_results,
		en: en.search_page_list_results,
		es: es.search_page_list_results,
		fa: fa.search_page_list_results,
		fr: fr.search_page_list_results,
		hi: hi.search_page_list_results,
		id: id.search_page_list_results,
		"pt-PT": pt_PT.search_page_list_results
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = (params = {}, options = {}) => {
	return {
		ar: ar.footer_menu_ads,
		de: de.footer_menu_ads,
		en: en.footer_menu_ads,
		es: es.footer_menu_ads,
		fa: fa.footer_menu_ads,
		fr: fr.footer_menu_ads,
		hi: hi.footer_menu_ads,
		id: id.footer_menu_ads,
		"pt-PT": pt_PT.footer_menu_ads
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = (params = {}, options = {}) => {
	return {
		ar: ar.footer_menu_api_doc,
		de: de.footer_menu_api_doc,
		en: en.footer_menu_api_doc,
		es: es.footer_menu_api_doc,
		fa: fa.footer_menu_api_doc,
		fr: fr.footer_menu_api_doc,
		hi: hi.footer_menu_api_doc,
		id: id.footer_menu_api_doc,
		"pt-PT": pt_PT.footer_menu_api_doc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = (params = {}, options = {}) => {
	return {
		ar: ar.setting_menu_title,
		de: de.setting_menu_title,
		en: en.setting_menu_title,
		es: es.setting_menu_title,
		fa: fa.setting_menu_title,
		fr: fr.setting_menu_title,
		hi: hi.setting_menu_title,
		id: id.setting_menu_title,
		"pt-PT": pt_PT.setting_menu_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = (params = {}, options = {}) => {
	return {
		ar: ar.setting_menu_dark_theme,
		de: de.setting_menu_dark_theme,
		en: en.setting_menu_dark_theme,
		es: es.setting_menu_dark_theme,
		fa: fa.setting_menu_dark_theme,
		fr: fr.setting_menu_dark_theme,
		hi: hi.setting_menu_dark_theme,
		id: id.setting_menu_dark_theme,
		"pt-PT": pt_PT.setting_menu_dark_theme
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = (params = {}, options = {}) => {
	return {
		ar: ar.setting_menu_light_theme,
		de: de.setting_menu_light_theme,
		en: en.setting_menu_light_theme,
		es: es.setting_menu_light_theme,
		fa: fa.setting_menu_light_theme,
		fr: fr.setting_menu_light_theme,
		hi: hi.setting_menu_light_theme,
		id: id.setting_menu_light_theme,
		"pt-PT": pt_PT.setting_menu_light_theme
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = (params = {}, options = {}) => {
	return {
		ar: ar.setting_menu_site_language,
		de: de.setting_menu_site_language,
		en: en.setting_menu_site_language,
		es: es.setting_menu_site_language,
		fa: fa.setting_menu_site_language,
		fr: fr.setting_menu_site_language,
		hi: hi.setting_menu_site_language,
		id: id.setting_menu_site_language,
		"pt-PT": pt_PT.setting_menu_site_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = (params = {}, options = {}) => {
	return {
		ar: ar.setting_menu_filter_languages,
		de: de.setting_menu_filter_languages,
		en: en.setting_menu_filter_languages,
		es: es.setting_menu_filter_languages,
		fa: fa.setting_menu_filter_languages,
		fr: fr.setting_menu_filter_languages,
		hi: hi.setting_menu_filter_languages,
		id: id.setting_menu_filter_languages,
		"pt-PT": pt_PT.setting_menu_filter_languages
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_title,
		de: de.subtitles_table_title,
		en: en.subtitles_table_title,
		es: es.subtitles_table_title,
		fa: fa.subtitles_table_title,
		fr: fr.subtitles_table_title,
		hi: hi.subtitles_table_title,
		id: id.subtitles_table_title,
		"pt-PT": pt_PT.subtitles_table_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_quality,
		de: de.subtitles_table_quality,
		en: en.subtitles_table_quality,
		es: es.subtitles_table_quality,
		fa: fa.subtitles_table_quality,
		fr: fr.subtitles_table_quality,
		hi: hi.subtitles_table_quality,
		id: id.subtitles_table_quality,
		"pt-PT": pt_PT.subtitles_table_quality
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_author,
		de: de.subtitles_table_author,
		en: en.subtitles_table_author,
		es: es.subtitles_table_author,
		fa: fa.subtitles_table_author,
		fr: fr.subtitles_table_author,
		hi: hi.subtitles_table_author,
		id: id.subtitles_table_author,
		"pt-PT": pt_PT.subtitles_table_author
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_releases,
		de: de.subtitles_table_releases,
		en: en.subtitles_table_releases,
		es: es.subtitles_table_releases,
		fa: fa.subtitles_table_releases,
		fr: fr.subtitles_table_releases,
		hi: hi.subtitles_table_releases,
		id: id.subtitles_table_releases,
		"pt-PT": pt_PT.subtitles_table_releases
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_rate,
		de: de.subtitles_table_rate,
		en: en.subtitles_table_rate,
		es: es.subtitles_table_rate,
		fa: fa.subtitles_table_rate,
		fr: fr.subtitles_table_rate,
		hi: hi.subtitles_table_rate,
		id: id.subtitles_table_rate,
		"pt-PT": pt_PT.subtitles_table_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_date,
		de: de.subtitles_table_date,
		en: en.subtitles_table_date,
		es: es.subtitles_table_date,
		fa: fa.subtitles_table_date,
		fr: fr.subtitles_table_date,
		hi: hi.subtitles_table_date,
		id: id.subtitles_table_date,
		"pt-PT": pt_PT.subtitles_table_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_comment,
		de: de.subtitles_table_comment,
		en: en.subtitles_table_comment,
		es: es.subtitles_table_comment,
		fa: fa.subtitles_table_comment,
		fr: fr.subtitles_table_comment,
		hi: hi.subtitles_table_comment,
		id: id.subtitles_table_comment,
		"pt-PT": pt_PT.subtitles_table_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_n_id,
		de: de.subtitles_table_n_id,
		en: en.subtitles_table_n_id,
		es: es.subtitles_table_n_id,
		fa: fa.subtitles_table_n_id,
		fr: fr.subtitles_table_n_id,
		hi: hi.subtitles_table_n_id,
		id: id.subtitles_table_n_id,
		"pt-PT": pt_PT.subtitles_table_n_id
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_slug,
		de: de.subtitles_table_slug,
		en: en.subtitles_table_slug,
		es: es.subtitles_table_slug,
		fa: fa.subtitles_table_slug,
		fr: fr.subtitles_table_slug,
		hi: hi.subtitles_table_slug,
		id: id.subtitles_table_slug,
		"pt-PT": pt_PT.subtitles_table_slug
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = (params = {}, options = {}) => {
	return {
		ar: ar.subtitles_table_link,
		de: de.subtitles_table_link,
		en: en.subtitles_table_link,
		es: es.subtitles_table_link,
		fa: fa.subtitles_table_link,
		fr: fr.subtitles_table_link,
		hi: hi.subtitles_table_link,
		id: id.subtitles_table_link,
		"pt-PT": pt_PT.subtitles_table_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = (params = {}, options = {}) => {
	return {
		ar: ar.loading,
		de: de.loading,
		en: en.loading,
		es: es.loading,
		fa: fa.loading,
		fr: fr.loading,
		hi: hi.loading,
		id: id.loading,
		"pt-PT": pt_PT.loading
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_title,
		de: de.subtitle_page_title,
		en: en.subtitle_page_title,
		es: es.subtitle_page_title,
		fa: fa.subtitle_page_title,
		fr: fr.subtitle_page_title,
		hi: hi.subtitle_page_title,
		id: id.subtitle_page_title,
		"pt-PT": pt_PT.subtitle_page_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_title_season,
		de: de.subtitle_page_title_season,
		en: en.subtitle_page_title_season,
		es: es.subtitle_page_title_season,
		fa: fa.subtitle_page_title_season,
		fr: fr.subtitle_page_title_season,
		hi: hi.subtitle_page_title_season,
		id: id.subtitle_page_title_season,
		"pt-PT": pt_PT.subtitle_page_title_season
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_title_season_number,
		de: de.subtitle_page_title_season_number,
		en: en.subtitle_page_title_season_number,
		es: es.subtitle_page_title_season_number,
		fa: fa.subtitle_page_title_season_number,
		fr: fr.subtitle_page_title_season_number,
		hi: hi.subtitle_page_title_season_number,
		id: id.subtitle_page_title_season_number,
		"pt-PT": pt_PT.subtitle_page_title_season_number
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_release_info,
		de: de.subtitle_page_release_info,
		en: en.subtitle_page_release_info,
		es: es.subtitle_page_release_info,
		fa: fa.subtitle_page_release_info,
		fr: fr.subtitle_page_release_info,
		hi: hi.subtitle_page_release_info,
		id: id.subtitle_page_release_info,
		"pt-PT": pt_PT.subtitle_page_release_info
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_commentary_by,
		de: de.subtitle_page_commentary_by,
		en: en.subtitle_page_commentary_by,
		es: es.subtitle_page_commentary_by,
		fa: fa.subtitle_page_commentary_by,
		fr: fr.subtitle_page_commentary_by,
		hi: hi.subtitle_page_commentary_by,
		id: id.subtitle_page_commentary_by,
		"pt-PT": pt_PT.subtitle_page_commentary_by
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_tags,
		de: de.subtitle_page_tags,
		en: en.subtitle_page_tags,
		es: es.subtitle_page_tags,
		fa: fa.subtitle_page_tags,
		fr: fr.subtitle_page_tags,
		hi: hi.subtitle_page_tags,
		id: id.subtitle_page_tags,
		"pt-PT": pt_PT.subtitle_page_tags
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_comments,
		de: de.subtitle_page_comments,
		en: en.subtitle_page_comments,
		es: es.subtitle_page_comments,
		fa: fa.subtitle_page_comments,
		fr: fr.subtitle_page_comments,
		hi: hi.subtitle_page_comments,
		id: id.subtitle_page_comments,
		"pt-PT": pt_PT.subtitle_page_comments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_download,
		de: de.subtitle_page_download,
		en: en.subtitle_page_download,
		es: es.subtitle_page_download,
		fa: fa.subtitle_page_download,
		fr: fr.subtitle_page_download,
		hi: hi.subtitle_page_download,
		id: id.subtitle_page_download,
		"pt-PT": pt_PT.subtitle_page_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ language: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_sup_title,
		de: de.subtitle_page_sup_title,
		en: en.subtitle_page_sup_title,
		es: es.subtitle_page_sup_title,
		fa: fa.subtitle_page_sup_title,
		fr: fr.subtitle_page_sup_title,
		hi: hi.subtitle_page_sup_title,
		id: id.subtitle_page_sup_title,
		"pt-PT": pt_PT.subtitle_page_sup_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ language: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_main_download_button,
		de: de.subtitle_page_main_download_button,
		en: en.subtitle_page_main_download_button,
		es: es.subtitle_page_main_download_button,
		fa: fa.subtitle_page_main_download_button,
		fr: fr.subtitle_page_main_download_button,
		hi: hi.subtitle_page_main_download_button,
		id: id.subtitle_page_main_download_button,
		"pt-PT": pt_PT.subtitle_page_main_download_button
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ language: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_mobile_button,
		de: de.subtitle_page_mobile_button,
		en: en.subtitle_page_mobile_button,
		es: es.subtitle_page_mobile_button,
		fa: fa.subtitle_page_mobile_button,
		fr: fr.subtitle_page_mobile_button,
		hi: hi.subtitle_page_mobile_button,
		id: id.subtitle_page_mobile_button,
		"pt-PT": pt_PT.subtitle_page_mobile_button
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_seasons = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_seasons,
		de: de.subtitle_page_seasons,
		en: en.subtitle_page_seasons,
		es: es.subtitle_page_seasons,
		fa: fa.subtitle_page_seasons,
		fr: fr.subtitle_page_seasons,
		hi: hi.subtitle_page_seasons,
		id: id.subtitle_page_seasons,
		"pt-PT": pt_PT.subtitle_page_seasons
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_season = (params , options = {}) => {
	return {
		ar: ar.subtitle_page_season,
		de: de.subtitle_page_season,
		en: en.subtitle_page_season,
		es: es.subtitle_page_season,
		fa: fa.subtitle_page_season,
		fr: fr.subtitle_page_season,
		hi: hi.subtitle_page_season,
		id: id.subtitle_page_season,
		"pt-PT": pt_PT.subtitle_page_season
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_rate_subtitle,
		de: de.subtitle_page_rate_subtitle,
		en: en.subtitle_page_rate_subtitle,
		es: es.subtitle_page_rate_subtitle,
		fa: fa.subtitle_page_rate_subtitle,
		fr: fr.subtitle_page_rate_subtitle,
		hi: hi.subtitle_page_rate_subtitle,
		id: id.subtitle_page_rate_subtitle,
		"pt-PT": pt_PT.subtitle_page_rate_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_rate_and_comment,
		de: de.subtitle_page_rate_and_comment,
		en: en.subtitle_page_rate_and_comment,
		es: es.subtitle_page_rate_and_comment,
		fa: fa.subtitle_page_rate_and_comment,
		fr: fr.subtitle_page_rate_and_comment,
		hi: hi.subtitle_page_rate_and_comment,
		id: id.subtitle_page_rate_and_comment,
		"pt-PT": pt_PT.subtitle_page_rate_and_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_comment,
		de: de.subtitle_page_comment,
		en: en.subtitle_page_comment,
		es: es.subtitle_page_comment,
		fa: fa.subtitle_page_comment,
		fr: fr.subtitle_page_comment,
		hi: hi.subtitle_page_comment,
		id: id.subtitle_page_comment,
		"pt-PT": pt_PT.subtitle_page_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_send,
		de: de.subtitle_page_send,
		en: en.subtitle_page_send,
		es: es.subtitle_page_send,
		fa: fa.subtitle_page_send,
		fr: fr.subtitle_page_send,
		hi: hi.subtitle_page_send,
		id: id.subtitle_page_send,
		"pt-PT": pt_PT.subtitle_page_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_close,
		de: de.subtitle_page_close,
		en: en.subtitle_page_close,
		es: es.subtitle_page_close,
		fa: fa.subtitle_page_close,
		fr: fr.subtitle_page_close,
		hi: hi.subtitle_page_close,
		id: id.subtitle_page_close,
		"pt-PT": pt_PT.subtitle_page_close
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_published_on,
		de: de.subtitle_page_published_on,
		en: en.subtitle_page_published_on,
		es: es.subtitle_page_published_on,
		fa: fa.subtitle_page_published_on,
		fr: fr.subtitle_page_published_on,
		hi: hi.subtitle_page_published_on,
		id: id.subtitle_page_published_on,
		"pt-PT": pt_PT.subtitle_page_published_on
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_rate,
		de: de.subtitle_page_rate,
		en: en.subtitle_page_rate,
		es: es.subtitle_page_rate,
		fa: fa.subtitle_page_rate,
		fr: fr.subtitle_page_rate,
		hi: hi.subtitle_page_rate,
		id: id.subtitle_page_rate,
		"pt-PT": pt_PT.subtitle_page_rate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_downloads,
		de: de.subtitle_page_downloads,
		en: en.subtitle_page_downloads,
		es: es.subtitle_page_downloads,
		fa: fa.subtitle_page_downloads,
		fr: fr.subtitle_page_downloads,
		hi: hi.subtitle_page_downloads,
		id: id.subtitle_page_downloads,
		"pt-PT": pt_PT.subtitle_page_downloads
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_hearing_impaired,
		de: de.subtitle_page_hearing_impaired,
		en: en.subtitle_page_hearing_impaired,
		es: es.subtitle_page_hearing_impaired,
		fa: fa.subtitle_page_hearing_impaired,
		fr: fr.subtitle_page_hearing_impaired,
		hi: hi.subtitle_page_hearing_impaired,
		id: id.subtitle_page_hearing_impaired,
		"pt-PT": pt_PT.subtitle_page_hearing_impaired
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = (params = {}, options = {}) => {
	return {
		ar: ar.subtitle_page_submit_comment,
		de: de.subtitle_page_submit_comment,
		en: en.subtitle_page_submit_comment,
		es: es.subtitle_page_submit_comment,
		fa: fa.subtitle_page_submit_comment,
		fr: fr.subtitle_page_submit_comment,
		hi: hi.subtitle_page_submit_comment,
		id: id.subtitle_page_submit_comment,
		"pt-PT": pt_PT.subtitle_page_submit_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_sup,
		de: de.author_page_sup,
		en: en.author_page_sup,
		es: es.author_page_sup,
		fa: fa.author_page_sup,
		fr: fr.author_page_sup,
		hi: hi.author_page_sup,
		id: id.author_page_sup,
		"pt-PT": pt_PT.author_page_sup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_rating,
		de: de.author_page_rating,
		en: en.author_page_rating,
		es: es.author_page_rating,
		fa: fa.author_page_rating,
		fr: fr.author_page_rating,
		hi: hi.author_page_rating,
		id: id.author_page_rating,
		"pt-PT": pt_PT.author_page_rating
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_downloads,
		de: de.author_page_downloads,
		en: en.author_page_downloads,
		es: es.author_page_downloads,
		fa: fa.author_page_downloads,
		fr: fr.author_page_downloads,
		hi: hi.author_page_downloads,
		id: id.author_page_downloads,
		"pt-PT": pt_PT.author_page_downloads
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_comments,
		de: de.author_page_comments,
		en: en.author_page_comments,
		es: es.author_page_comments,
		fa: fa.author_page_comments,
		fr: fr.author_page_comments,
		hi: hi.author_page_comments,
		id: id.author_page_comments,
		"pt-PT": pt_PT.author_page_comments
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_latest_subtitles,
		de: de.author_page_latest_subtitles,
		en: en.author_page_latest_subtitles,
		es: es.author_page_latest_subtitles,
		fa: fa.author_page_latest_subtitles,
		fr: fr.author_page_latest_subtitles,
		hi: hi.author_page_latest_subtitles,
		id: id.author_page_latest_subtitles,
		"pt-PT": pt_PT.author_page_latest_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_pinned_subtitles,
		de: de.author_page_pinned_subtitles,
		en: en.author_page_pinned_subtitles,
		es: es.author_page_pinned_subtitles,
		fa: fa.author_page_pinned_subtitles,
		fr: fr.author_page_pinned_subtitles,
		hi: hi.author_page_pinned_subtitles,
		id: id.author_page_pinned_subtitles,
		"pt-PT": pt_PT.author_page_pinned_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_more_subtitles,
		de: de.author_page_more_subtitles,
		en: en.author_page_more_subtitles,
		es: es.author_page_more_subtitles,
		fa: fa.author_page_more_subtitles,
		fr: fr.author_page_more_subtitles,
		hi: hi.author_page_more_subtitles,
		id: id.author_page_more_subtitles,
		"pt-PT": pt_PT.author_page_more_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_subtitles,
		de: de.author_page_subtitles,
		en: en.author_page_subtitles,
		es: es.author_page_subtitles,
		fa: fa.author_page_subtitles,
		fr: fr.author_page_subtitles,
		hi: hi.author_page_subtitles,
		id: id.author_page_subtitles,
		"pt-PT": pt_PT.author_page_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_download,
		de: de.author_page_download,
		en: en.author_page_download,
		es: es.author_page_download,
		fa: fa.author_page_download,
		fr: fr.author_page_download,
		hi: hi.author_page_download,
		id: id.author_page_download,
		"pt-PT": pt_PT.author_page_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_hearing_impaired,
		de: de.author_page_hearing_impaired,
		en: en.author_page_hearing_impaired,
		es: es.author_page_hearing_impaired,
		fa: fa.author_page_hearing_impaired,
		fr: fr.author_page_hearing_impaired,
		hi: hi.author_page_hearing_impaired,
		id: id.author_page_hearing_impaired,
		"pt-PT": pt_PT.author_page_hearing_impaired
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_submit_comment,
		de: de.author_page_submit_comment,
		en: en.author_page_submit_comment,
		es: es.author_page_submit_comment,
		fa: fa.author_page_submit_comment,
		fr: fr.author_page_submit_comment,
		hi: hi.author_page_submit_comment,
		id: id.author_page_submit_comment,
		"pt-PT": pt_PT.author_page_submit_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_page,
		de: de.author_page_page,
		en: en.author_page_page,
		es: es.author_page_page,
		fa: fa.author_page_page,
		fr: fr.author_page_page,
		hi: hi.author_page_page,
		id: id.author_page_page,
		"pt-PT": pt_PT.author_page_page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_comment_sup,
		de: de.author_page_comment_sup,
		en: en.author_page_comment_sup,
		es: es.author_page_comment_sup,
		fa: fa.author_page_comment_sup,
		fr: fr.author_page_comment_sup,
		hi: hi.author_page_comment_sup,
		id: id.author_page_comment_sup,
		"pt-PT": pt_PT.author_page_comment_sup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = (params = {}, options = {}) => {
	return {
		ar: ar.author_page_rating_sup,
		de: de.author_page_rating_sup,
		en: en.author_page_rating_sup,
		es: es.author_page_rating_sup,
		fa: fa.author_page_rating_sup,
		fr: fr.author_page_rating_sup,
		hi: hi.author_page_rating_sup,
		id: id.author_page_rating_sup,
		"pt-PT": pt_PT.author_page_rating_sup
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = (params = {}, options = {}) => {
	return {
		ar: ar.next,
		de: de.next,
		en: en.next,
		es: es.next,
		fa: fa.next,
		fr: fr.next,
		hi: hi.next,
		id: id.next,
		"pt-PT": pt_PT.next
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = (params = {}, options = {}) => {
	return {
		ar: ar.previous,
		de: de.previous,
		en: en.previous,
		es: es.previous,
		fa: fa.previous,
		fr: fr.previous,
		hi: hi.previous,
		id: id.previous,
		"pt-PT": pt_PT.previous
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ lang: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params , options = {}) => {
	return {
		ar: ar.latest_page_title,
		de: de.latest_page_title,
		en: en.latest_page_title,
		es: es.latest_page_title,
		fa: fa.latest_page_title,
		fr: fr.latest_page_title,
		hi: hi.latest_page_title,
		id: id.latest_page_title,
		"pt-PT": pt_PT.latest_page_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.latest_page_subtitles,
		de: de.latest_page_subtitles,
		en: en.latest_page_subtitles,
		es: es.latest_page_subtitles,
		fa: fa.latest_page_subtitles,
		fr: fr.latest_page_subtitles,
		hi: hi.latest_page_subtitles,
		id: id.latest_page_subtitles,
		"pt-PT": pt_PT.latest_page_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = (params = {}, options = {}) => {
	return {
		ar: ar.latest_table_movie,
		de: de.latest_table_movie,
		en: en.latest_table_movie,
		es: es.latest_table_movie,
		fa: fa.latest_table_movie,
		fr: fr.latest_table_movie,
		hi: hi.latest_table_movie,
		id: id.latest_table_movie,
		"pt-PT": pt_PT.latest_table_movie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = (params = {}, options = {}) => {
	return {
		ar: ar.latest_table_name,
		de: de.latest_table_name,
		en: en.latest_table_name,
		es: es.latest_table_name,
		fa: fa.latest_table_name,
		fr: fr.latest_table_name,
		hi: hi.latest_table_name,
		id: id.latest_table_name,
		"pt-PT": pt_PT.latest_table_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = (params = {}, options = {}) => {
	return {
		ar: ar.latest_table_author,
		de: de.latest_table_author,
		en: en.latest_table_author,
		es: es.latest_table_author,
		fa: fa.latest_table_author,
		fr: fr.latest_table_author,
		hi: hi.latest_table_author,
		id: id.latest_table_author,
		"pt-PT": pt_PT.latest_table_author
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = (params = {}, options = {}) => {
	return {
		ar: ar.latest_table_downloads,
		de: de.latest_table_downloads,
		en: en.latest_table_downloads,
		es: es.latest_table_downloads,
		fa: fa.latest_table_downloads,
		fr: fr.latest_table_downloads,
		hi: hi.latest_table_downloads,
		id: id.latest_table_downloads,
		"pt-PT": pt_PT.latest_table_downloads
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = (params = {}, options = {}) => {
	return {
		ar: ar.latest_table_time,
		de: de.latest_table_time,
		en: en.latest_table_time,
		es: es.latest_table_time,
		fa: fa.latest_table_time,
		fr: fr.latest_table_time,
		hi: hi.latest_table_time,
		id: id.latest_table_time,
		"pt-PT": pt_PT.latest_table_time
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = (params = {}, options = {}) => {
	return {
		ar: ar.latest_page_caption,
		de: de.latest_page_caption,
		en: en.latest_page_caption,
		es: es.latest_page_caption,
		fa: fa.latest_page_caption,
		fr: fr.latest_page_caption,
		hi: hi.latest_page_caption,
		id: id.latest_page_caption,
		"pt-PT": pt_PT.latest_page_caption
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = (params = {}, options = {}) => {
	return {
		ar: ar.latest_seasons,
		de: de.latest_seasons,
		en: en.latest_seasons,
		es: es.latest_seasons,
		fa: fa.latest_seasons,
		fr: fr.latest_seasons,
		hi: hi.latest_seasons,
		id: id.latest_seasons,
		"pt-PT": pt_PT.latest_seasons
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav,
		de: de.user_nav,
		en: en.user_nav,
		es: es.user_nav,
		fa: fa.user_nav,
		fr: fr.user_nav,
		hi: hi.user_nav,
		id: id.user_nav,
		"pt-PT": pt_PT.user_nav
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_dashboard,
		de: de.user_nav_dashboard,
		en: en.user_nav_dashboard,
		es: es.user_nav_dashboard,
		fa: fa.user_nav_dashboard,
		fr: fr.user_nav_dashboard,
		hi: hi.user_nav_dashboard,
		id: id.user_nav_dashboard,
		"pt-PT": pt_PT.user_nav_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_upload,
		de: de.user_nav_upload,
		en: en.user_nav_upload,
		es: es.user_nav_upload,
		fa: fa.user_nav_upload,
		fr: fr.user_nav_upload,
		hi: hi.user_nav_upload,
		id: id.user_nav_upload,
		"pt-PT": pt_PT.user_nav_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_subtitles,
		de: de.user_nav_subtitles,
		en: en.user_nav_subtitles,
		es: es.user_nav_subtitles,
		fa: fa.user_nav_subtitles,
		fr: fr.user_nav_subtitles,
		hi: hi.user_nav_subtitles,
		id: id.user_nav_subtitles,
		"pt-PT": pt_PT.user_nav_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_profile,
		de: de.user_nav_profile,
		en: en.user_nav_profile,
		es: es.user_nav_profile,
		fa: fa.user_nav_profile,
		fr: fr.user_nav_profile,
		hi: hi.user_nav_profile,
		id: id.user_nav_profile,
		"pt-PT": pt_PT.user_nav_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_api,
		de: de.user_nav_api,
		en: en.user_nav_api,
		es: es.user_nav_api,
		fa: fa.user_nav_api,
		fr: fr.user_nav_api,
		hi: hi.user_nav_api,
		id: id.user_nav_api,
		"pt-PT": pt_PT.user_nav_api
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = (params = {}, options = {}) => {
	return {
		ar: ar.user_nav_logout,
		de: de.user_nav_logout,
		en: en.user_nav_logout,
		es: es.user_nav_logout,
		fa: fa.user_nav_logout,
		fr: fr.user_nav_logout,
		hi: hi.user_nav_logout,
		id: id.user_nav_logout,
		"pt-PT": pt_PT.user_nav_logout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_dashboard,
		de: de.quick_items_dashboard,
		en: en.quick_items_dashboard,
		es: es.quick_items_dashboard,
		fa: fa.quick_items_dashboard,
		fr: fr.quick_items_dashboard,
		hi: hi.quick_items_dashboard,
		id: id.quick_items_dashboard,
		"pt-PT": pt_PT.quick_items_dashboard
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_upload,
		de: de.quick_items_upload,
		en: en.quick_items_upload,
		es: es.quick_items_upload,
		fa: fa.quick_items_upload,
		fr: fr.quick_items_upload,
		hi: hi.quick_items_upload,
		id: id.quick_items_upload,
		"pt-PT": pt_PT.quick_items_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_upload_description,
		de: de.quick_items_upload_description,
		en: en.quick_items_upload_description,
		es: es.quick_items_upload_description,
		fa: fa.quick_items_upload_description,
		fr: fr.quick_items_upload_description,
		hi: hi.quick_items_upload_description,
		id: id.quick_items_upload_description,
		"pt-PT": pt_PT.quick_items_upload_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_subtitles,
		de: de.quick_items_subtitles,
		en: en.quick_items_subtitles,
		es: es.quick_items_subtitles,
		fa: fa.quick_items_subtitles,
		fr: fr.quick_items_subtitles,
		hi: hi.quick_items_subtitles,
		id: id.quick_items_subtitles,
		"pt-PT": pt_PT.quick_items_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_subtitles_description,
		de: de.quick_items_subtitles_description,
		en: en.quick_items_subtitles_description,
		es: es.quick_items_subtitles_description,
		fa: fa.quick_items_subtitles_description,
		fr: fr.quick_items_subtitles_description,
		hi: hi.quick_items_subtitles_description,
		id: id.quick_items_subtitles_description,
		"pt-PT": pt_PT.quick_items_subtitles_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_api,
		de: de.quick_items_api,
		en: en.quick_items_api,
		es: es.quick_items_api,
		fa: fa.quick_items_api,
		fr: fr.quick_items_api,
		hi: hi.quick_items_api,
		id: id.quick_items_api,
		"pt-PT": pt_PT.quick_items_api
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_api_description,
		de: de.quick_items_api_description,
		en: en.quick_items_api_description,
		es: es.quick_items_api_description,
		fa: fa.quick_items_api_description,
		fr: fr.quick_items_api_description,
		hi: hi.quick_items_api_description,
		id: id.quick_items_api_description,
		"pt-PT": pt_PT.quick_items_api_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_profile,
		de: de.quick_items_profile,
		en: en.quick_items_profile,
		es: es.quick_items_profile,
		fa: fa.quick_items_profile,
		fr: fr.quick_items_profile,
		hi: hi.quick_items_profile,
		id: id.quick_items_profile,
		"pt-PT": pt_PT.quick_items_profile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_profile_description,
		de: de.quick_items_profile_description,
		en: en.quick_items_profile_description,
		es: es.quick_items_profile_description,
		fa: fa.quick_items_profile_description,
		fr: fr.quick_items_profile_description,
		hi: hi.quick_items_profile_description,
		id: id.quick_items_profile_description,
		"pt-PT": pt_PT.quick_items_profile_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_title,
		de: de.upload_page_title,
		en: en.upload_page_title,
		es: es.upload_page_title,
		fa: fa.upload_page_title,
		fr: fr.upload_page_title,
		hi: hi.upload_page_title,
		id: id.upload_page_title,
		"pt-PT": pt_PT.upload_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_src_type,
		de: de.upload_page_src_type,
		en: en.upload_page_src_type,
		es: es.upload_page_src_type,
		fa: fa.upload_page_src_type,
		fr: fr.upload_page_src_type,
		hi: hi.upload_page_src_type,
		id: id.upload_page_src_type,
		"pt-PT": pt_PT.upload_page_src_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_choose_movie_tv,
		de: de.upload_page_choose_movie_tv,
		en: en.upload_page_choose_movie_tv,
		es: es.upload_page_choose_movie_tv,
		fa: fa.upload_page_choose_movie_tv,
		fr: fr.upload_page_choose_movie_tv,
		hi: hi.upload_page_choose_movie_tv,
		id: id.upload_page_choose_movie_tv,
		"pt-PT": pt_PT.upload_page_choose_movie_tv
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_drop_region,
		de: de.upload_page_drop_region,
		en: en.upload_page_drop_region,
		es: es.upload_page_drop_region,
		fa: fa.upload_page_drop_region,
		fr: fr.upload_page_drop_region,
		hi: hi.upload_page_drop_region,
		id: id.upload_page_drop_region,
		"pt-PT": pt_PT.upload_page_drop_region
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_files,
		de: de.upload_page_files,
		en: en.upload_page_files,
		es: es.upload_page_files,
		fa: fa.upload_page_files,
		fr: fr.upload_page_files,
		hi: hi.upload_page_files,
		id: id.upload_page_files,
		"pt-PT": pt_PT.upload_page_files
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_releases,
		de: de.upload_page_releases,
		en: en.upload_page_releases,
		es: es.upload_page_releases,
		fa: fa.upload_page_releases,
		fr: fr.upload_page_releases,
		hi: hi.upload_page_releases,
		id: id.upload_page_releases,
		"pt-PT": pt_PT.upload_page_releases
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_release_add,
		de: de.upload_page_release_add,
		en: en.upload_page_release_add,
		es: es.upload_page_release_add,
		fa: fa.upload_page_release_add,
		fr: fr.upload_page_release_add,
		hi: hi.upload_page_release_add,
		id: id.upload_page_release_add,
		"pt-PT": pt_PT.upload_page_release_add
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_release_remove,
		de: de.upload_page_release_remove,
		en: en.upload_page_release_remove,
		es: es.upload_page_release_remove,
		fa: fa.upload_page_release_remove,
		fr: fr.upload_page_release_remove,
		hi: hi.upload_page_release_remove,
		id: id.upload_page_release_remove,
		"pt-PT": pt_PT.upload_page_release_remove
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_language,
		de: de.upload_page_language,
		en: en.upload_page_language,
		es: es.upload_page_language,
		fa: fa.upload_page_language,
		fr: fr.upload_page_language,
		hi: hi.upload_page_language,
		id: id.upload_page_language,
		"pt-PT": pt_PT.upload_page_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_select_language,
		de: de.upload_page_select_language,
		en: en.upload_page_select_language,
		es: es.upload_page_select_language,
		fa: fa.upload_page_select_language,
		fr: fr.upload_page_select_language,
		hi: hi.upload_page_select_language,
		id: id.upload_page_select_language,
		"pt-PT": pt_PT.upload_page_select_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_release_name,
		de: de.upload_page_release_name,
		en: en.upload_page_release_name,
		es: es.upload_page_release_name,
		fa: fa.upload_page_release_name,
		fr: fr.upload_page_release_name,
		hi: hi.upload_page_release_name,
		id: id.upload_page_release_name,
		"pt-PT": pt_PT.upload_page_release_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_release_type,
		de: de.upload_page_release_type,
		en: en.upload_page_release_type,
		es: es.upload_page_release_type,
		fa: fa.upload_page_release_type,
		fr: fr.upload_page_release_type,
		hi: hi.upload_page_release_type,
		id: id.upload_page_release_type,
		"pt-PT": pt_PT.upload_page_release_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_quality = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_select_quality,
		de: de.upload_page_select_quality,
		en: en.upload_page_select_quality,
		es: es.upload_page_select_quality,
		fa: fa.upload_page_select_quality,
		fr: fr.upload_page_select_quality,
		hi: hi.upload_page_select_quality,
		id: id.upload_page_select_quality,
		"pt-PT": pt_PT.upload_page_select_quality
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_production_type = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_production_type,
		de: de.upload_page_production_type,
		en: en.upload_page_production_type,
		es: es.upload_page_production_type,
		fa: fa.upload_page_production_type,
		fr: fr.upload_page_production_type,
		hi: hi.upload_page_production_type,
		id: id.upload_page_production_type,
		"pt-PT": pt_PT.upload_page_production_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_production_type = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_select_production_type,
		de: de.upload_page_select_production_type,
		en: en.upload_page_select_production_type,
		es: es.upload_page_select_production_type,
		fa: fa.upload_page_select_production_type,
		fr: fr.upload_page_select_production_type,
		hi: hi.upload_page_select_production_type,
		id: id.upload_page_select_production_type,
		"pt-PT": pt_PT.upload_page_select_production_type
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_remove_movie,
		de: de.upload_page_remove_movie,
		en: en.upload_page_remove_movie,
		es: es.upload_page_remove_movie,
		fa: fa.upload_page_remove_movie,
		fr: fr.upload_page_remove_movie,
		hi: hi.upload_page_remove_movie,
		id: id.upload_page_remove_movie,
		"pt-PT": pt_PT.upload_page_remove_movie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_prod_type_transcript,
		de: de.upload_page_prod_type_transcript,
		en: en.upload_page_prod_type_transcript,
		es: es.upload_page_prod_type_transcript,
		fa: fa.upload_page_prod_type_transcript,
		fr: fr.upload_page_prod_type_transcript,
		hi: hi.upload_page_prod_type_transcript,
		id: id.upload_page_prod_type_transcript,
		"pt-PT": pt_PT.upload_page_prod_type_transcript
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_prod_type_translated,
		de: de.upload_page_prod_type_translated,
		en: en.upload_page_prod_type_translated,
		es: es.upload_page_prod_type_translated,
		fa: fa.upload_page_prod_type_translated,
		fr: fr.upload_page_prod_type_translated,
		hi: hi.upload_page_prod_type_translated,
		id: id.upload_page_prod_type_translated,
		"pt-PT": pt_PT.upload_page_prod_type_translated
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_prod_type_improved,
		de: de.upload_page_prod_type_improved,
		en: en.upload_page_prod_type_improved,
		es: es.upload_page_prod_type_improved,
		fa: fa.upload_page_prod_type_improved,
		fr: fr.upload_page_prod_type_improved,
		hi: hi.upload_page_prod_type_improved,
		id: id.upload_page_prod_type_improved,
		"pt-PT": pt_PT.upload_page_prod_type_improved
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_prod_type_retail,
		de: de.upload_page_prod_type_retail,
		en: en.upload_page_prod_type_retail,
		es: es.upload_page_prod_type_retail,
		fa: fa.upload_page_prod_type_retail,
		fr: fr.upload_page_prod_type_retail,
		hi: hi.upload_page_prod_type_retail,
		id: id.upload_page_prod_type_retail,
		"pt-PT": pt_PT.upload_page_prod_type_retail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_prod_type_machine,
		de: de.upload_page_prod_type_machine,
		en: en.upload_page_prod_type_machine,
		es: es.upload_page_prod_type_machine,
		fa: fa.upload_page_prod_type_machine,
		fr: fr.upload_page_prod_type_machine,
		hi: hi.upload_page_prod_type_machine,
		id: id.upload_page_prod_type_machine,
		"pt-PT": pt_PT.upload_page_prod_type_machine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_season,
		de: de.upload_page_season,
		en: en.upload_page_season,
		es: es.upload_page_season,
		fa: fa.upload_page_season,
		fr: fr.upload_page_season,
		hi: hi.upload_page_season,
		id: id.upload_page_season,
		"pt-PT": pt_PT.upload_page_season
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_episode,
		de: de.upload_page_episode,
		en: en.upload_page_episode,
		es: es.upload_page_episode,
		fa: fa.upload_page_episode,
		fr: fr.upload_page_episode,
		hi: hi.upload_page_episode,
		id: id.upload_page_episode,
		"pt-PT": pt_PT.upload_page_episode
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_full_season,
		de: de.upload_page_full_season,
		en: en.upload_page_full_season,
		es: es.upload_page_full_season,
		fa: fa.upload_page_full_season,
		fr: fr.upload_page_full_season,
		hi: hi.upload_page_full_season,
		id: id.upload_page_full_season,
		"pt-PT": pt_PT.upload_page_full_season
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_comment,
		de: de.upload_page_comment,
		en: en.upload_page_comment,
		es: es.upload_page_comment,
		fa: fa.upload_page_comment,
		fr: fr.upload_page_comment,
		hi: hi.upload_page_comment,
		id: id.upload_page_comment,
		"pt-PT": pt_PT.upload_page_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_hearing_impaired,
		de: de.upload_page_hearing_impaired,
		en: en.upload_page_hearing_impaired,
		es: es.upload_page_hearing_impaired,
		fa: fa.upload_page_hearing_impaired,
		fr: fr.upload_page_hearing_impaired,
		hi: hi.upload_page_hearing_impaired,
		id: id.upload_page_hearing_impaired,
		"pt-PT": pt_PT.upload_page_hearing_impaired
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_fps,
		de: de.upload_page_fps,
		en: en.upload_page_fps,
		es: es.upload_page_fps,
		fa: fa.upload_page_fps,
		fr: fr.upload_page_fps,
		hi: hi.upload_page_fps,
		id: id.upload_page_fps,
		"pt-PT": pt_PT.upload_page_fps
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_submit,
		de: de.upload_page_submit,
		en: en.upload_page_submit,
		es: es.upload_page_submit,
		fa: fa.upload_page_submit,
		fr: fr.upload_page_submit,
		hi: hi.upload_page_submit,
		id: id.upload_page_submit,
		"pt-PT": pt_PT.upload_page_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_fps_default,
		de: de.upload_page_fps_default,
		en: en.upload_page_fps_default,
		es: es.upload_page_fps_default,
		fa: fa.upload_page_fps_default,
		fr: fr.upload_page_fps_default,
		hi: hi.upload_page_fps_default,
		id: id.upload_page_fps_default,
		"pt-PT": pt_PT.upload_page_fps_default
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.edit_subtitle_page_title,
		de: de.edit_subtitle_page_title,
		en: en.edit_subtitle_page_title,
		es: es.edit_subtitle_page_title,
		fa: fa.edit_subtitle_page_title,
		fr: fr.edit_subtitle_page_title,
		hi: hi.edit_subtitle_page_title,
		id: id.edit_subtitle_page_title,
		"pt-PT": pt_PT.edit_subtitle_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = (params = {}, options = {}) => {
	return {
		ar: ar.quick_download,
		de: de.quick_download,
		en: en.quick_download,
		es: es.quick_download,
		fa: fa.quick_download,
		fr: fr.quick_download,
		hi: hi.quick_download,
		id: id.quick_download,
		"pt-PT": pt_PT.quick_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params , options = {}) => {
	return {
		ar: ar.season,
		de: de.season,
		en: en.season,
		es: es.season,
		fa: fa.season,
		fr: fr.season,
		hi: hi.season,
		id: id.season,
		"pt-PT": pt_PT.season
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_title,
		de: de.register_page_title,
		en: en.register_page_title,
		es: es.register_page_title,
		fa: fa.register_page_title,
		fr: fr.register_page_title,
		hi: hi.register_page_title,
		id: id.register_page_title,
		"pt-PT": pt_PT.register_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_description,
		de: de.register_page_description,
		en: en.register_page_description,
		es: es.register_page_description,
		fa: fa.register_page_description,
		fr: fr.register_page_description,
		hi: hi.register_page_description,
		id: id.register_page_description,
		"pt-PT": pt_PT.register_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_form_title,
		de: de.register_page_form_title,
		en: en.register_page_form_title,
		es: es.register_page_form_title,
		fa: fa.register_page_form_title,
		fr: fr.register_page_form_title,
		hi: hi.register_page_form_title,
		id: id.register_page_form_title,
		"pt-PT": pt_PT.register_page_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_form_subtitle,
		de: de.register_page_form_subtitle,
		en: en.register_page_form_subtitle,
		es: es.register_page_form_subtitle,
		fa: fa.register_page_form_subtitle,
		fr: fr.register_page_form_subtitle,
		hi: hi.register_page_form_subtitle,
		id: id.register_page_form_subtitle,
		"pt-PT": pt_PT.register_page_form_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_email,
		de: de.register_page_email,
		en: en.register_page_email,
		es: es.register_page_email,
		fa: fa.register_page_email,
		fr: fr.register_page_email,
		hi: hi.register_page_email,
		id: id.register_page_email,
		"pt-PT": pt_PT.register_page_email
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_username = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_username,
		de: de.register_page_username,
		en: en.register_page_username,
		es: es.register_page_username,
		fa: fa.register_page_username,
		fr: fr.register_page_username,
		hi: hi.register_page_username,
		id: id.register_page_username,
		"pt-PT": pt_PT.register_page_username
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_password,
		de: de.register_page_password,
		en: en.register_page_password,
		es: es.register_page_password,
		fa: fa.register_page_password,
		fr: fr.register_page_password,
		hi: hi.register_page_password,
		id: id.register_page_password,
		"pt-PT": pt_PT.register_page_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_country,
		de: de.register_page_country,
		en: en.register_page_country,
		es: es.register_page_country,
		fa: fa.register_page_country,
		fr: fr.register_page_country,
		hi: hi.register_page_country,
		id: id.register_page_country,
		"pt-PT": pt_PT.register_page_country
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_create_account,
		de: de.register_page_create_account,
		en: en.register_page_create_account,
		es: es.register_page_create_account,
		fa: fa.register_page_create_account,
		fr: fr.register_page_create_account,
		hi: hi.register_page_create_account,
		id: id.register_page_create_account,
		"pt-PT": pt_PT.register_page_create_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_sign_up_with_google,
		de: de.register_page_sign_up_with_google,
		en: en.register_page_sign_up_with_google,
		es: es.register_page_sign_up_with_google,
		fa: fa.register_page_sign_up_with_google,
		fr: fr.register_page_sign_up_with_google,
		hi: hi.register_page_sign_up_with_google,
		id: id.register_page_sign_up_with_google,
		"pt-PT": pt_PT.register_page_sign_up_with_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_have_an_account,
		de: de.register_page_have_an_account,
		en: en.register_page_have_an_account,
		es: es.register_page_have_an_account,
		fa: fa.register_page_have_an_account,
		fr: fr.register_page_have_an_account,
		hi: hi.register_page_have_an_account,
		id: id.register_page_have_an_account,
		"pt-PT": pt_PT.register_page_have_an_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_sign_in,
		de: de.register_page_sign_in,
		en: en.register_page_sign_in,
		es: es.register_page_sign_in,
		fa: fa.register_page_sign_in,
		fr: fr.register_page_sign_in,
		hi: hi.register_page_sign_in,
		id: id.register_page_sign_in,
		"pt-PT": pt_PT.register_page_sign_in
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_title,
		de: de.login_page_title,
		en: en.login_page_title,
		es: es.login_page_title,
		fa: fa.login_page_title,
		fr: fr.login_page_title,
		hi: hi.login_page_title,
		id: id.login_page_title,
		"pt-PT": pt_PT.login_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_description,
		de: de.login_page_description,
		en: en.login_page_description,
		es: es.login_page_description,
		fa: fa.login_page_description,
		fr: fr.login_page_description,
		hi: hi.login_page_description,
		id: id.login_page_description,
		"pt-PT": pt_PT.login_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_form_title,
		de: de.login_page_form_title,
		en: en.login_page_form_title,
		es: es.login_page_form_title,
		fa: fa.login_page_form_title,
		fr: fr.login_page_form_title,
		hi: hi.login_page_form_title,
		id: id.login_page_form_title,
		"pt-PT": pt_PT.login_page_form_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_form_subtitle,
		de: de.login_page_form_subtitle,
		en: en.login_page_form_subtitle,
		es: es.login_page_form_subtitle,
		fa: fa.login_page_form_subtitle,
		fr: fr.login_page_form_subtitle,
		hi: hi.login_page_form_subtitle,
		id: id.login_page_form_subtitle,
		"pt-PT": pt_PT.login_page_form_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_button,
		de: de.login_page_button,
		en: en.login_page_button,
		es: es.login_page_button,
		fa: fa.login_page_button,
		fr: fr.login_page_button,
		hi: hi.login_page_button,
		id: id.login_page_button,
		"pt-PT": pt_PT.login_page_button
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_with_google,
		de: de.login_page_with_google,
		en: en.login_page_with_google,
		es: es.login_page_with_google,
		fa: fa.login_page_with_google,
		fr: fr.login_page_with_google,
		hi: hi.login_page_with_google,
		id: id.login_page_with_google,
		"pt-PT": pt_PT.login_page_with_google
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_have_an_account,
		de: de.login_page_have_an_account,
		en: en.login_page_have_an_account,
		es: es.login_page_have_an_account,
		fa: fa.login_page_have_an_account,
		fr: fr.login_page_have_an_account,
		hi: hi.login_page_have_an_account,
		id: id.login_page_have_an_account,
		"pt-PT": pt_PT.login_page_have_an_account
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_sign_up,
		de: de.login_page_sign_up,
		en: en.login_page_sign_up,
		es: es.login_page_sign_up,
		fa: fa.login_page_sign_up,
		fr: fr.login_page_sign_up,
		hi: hi.login_page_sign_up,
		id: id.login_page_sign_up,
		"pt-PT": pt_PT.login_page_sign_up
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = (params = {}, options = {}) => {
	return {
		ar: ar.login_page_forget_password,
		de: de.login_page_forget_password,
		en: en.login_page_forget_password,
		es: es.login_page_forget_password,
		fa: fa.login_page_forget_password,
		fr: fr.login_page_forget_password,
		hi: hi.login_page_forget_password,
		id: id.login_page_forget_password,
		"pt-PT": pt_PT.login_page_forget_password
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = (params = {}, options = {}) => {
	return {
		ar: ar.page,
		de: de.page,
		en: en.page,
		es: es.page,
		fa: fa.page,
		fr: fr.page,
		hi: hi.page,
		id: id.page,
		"pt-PT": pt_PT.page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = (params = {}, options = {}) => {
	return {
		ar: ar.logout_title,
		de: de.logout_title,
		en: en.logout_title,
		es: es.logout_title,
		fa: fa.logout_title,
		fr: fr.logout_title,
		hi: hi.logout_title,
		id: id.logout_title,
		"pt-PT": pt_PT.logout_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = (params = {}, options = {}) => {
	return {
		ar: ar.logout_description,
		de: de.logout_description,
		en: en.logout_description,
		es: es.logout_description,
		fa: fa.logout_description,
		fr: fr.logout_description,
		hi: hi.logout_description,
		id: id.logout_description,
		"pt-PT": pt_PT.logout_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = (params = {}, options = {}) => {
	return {
		ar: ar.search_filter,
		de: de.search_filter,
		en: en.search_filter,
		es: es.search_filter,
		fa: fa.search_filter,
		fr: fr.search_filter,
		hi: hi.search_filter,
		id: id.search_filter,
		"pt-PT": pt_PT.search_filter
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = (params = {}, options = {}) => {
	return {
		ar: ar.search_filter_description,
		de: de.search_filter_description,
		en: en.search_filter_description,
		es: es.search_filter_description,
		fa: fa.search_filter_description,
		fr: fr.search_filter_description,
		hi: hi.search_filter_description,
		id: id.search_filter_description,
		"pt-PT": pt_PT.search_filter_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.mysubtitles_page_title,
		de: de.mysubtitles_page_title,
		en: en.mysubtitles_page_title,
		es: es.mysubtitles_page_title,
		fa: fa.mysubtitles_page_title,
		fr: fr.mysubtitles_page_title,
		hi: hi.mysubtitles_page_title,
		id: id.mysubtitles_page_title,
		"pt-PT": pt_PT.mysubtitles_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.mysubtitles_page_description,
		de: de.mysubtitles_page_description,
		en: en.mysubtitles_page_description,
		es: es.mysubtitles_page_description,
		fa: fa.mysubtitles_page_description,
		fr: fr.mysubtitles_page_description,
		hi: hi.mysubtitles_page_description,
		id: id.mysubtitles_page_description,
		"pt-PT": pt_PT.mysubtitles_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = (params = {}, options = {}) => {
	return {
		ar: ar.add_subtitle,
		de: de.add_subtitle,
		en: en.add_subtitle,
		es: es.add_subtitle,
		fa: fa.add_subtitle,
		fr: fr.add_subtitle,
		hi: hi.add_subtitle,
		id: id.add_subtitle,
		"pt-PT": pt_PT.add_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle = (params = {}, options = {}) => {
	return {
		ar: ar.edit_subtitle,
		de: de.edit_subtitle,
		en: en.edit_subtitle,
		es: es.edit_subtitle,
		fa: fa.edit_subtitle,
		fr: fr.edit_subtitle,
		hi: hi.edit_subtitle,
		id: id.edit_subtitle,
		"pt-PT": pt_PT.edit_subtitle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = (params = {}, options = {}) => {
	return {
		ar: ar.not_found_title,
		de: de.not_found_title,
		en: en.not_found_title,
		es: es.not_found_title,
		fa: fa.not_found_title,
		fr: fr.not_found_title,
		hi: hi.not_found_title,
		id: id.not_found_title,
		"pt-PT": pt_PT.not_found_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = (params = {}, options = {}) => {
	return {
		ar: ar.not_found_description,
		de: de.not_found_description,
		en: en.not_found_description,
		es: es.not_found_description,
		fa: fa.not_found_description,
		fr: fr.not_found_description,
		hi: hi.not_found_description,
		id: id.not_found_description,
		"pt-PT": pt_PT.not_found_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = (params = {}, options = {}) => {
	return {
		ar: ar.not_found_go_home,
		de: de.not_found_go_home,
		en: en.not_found_go_home,
		es: es.not_found_go_home,
		fa: fa.not_found_go_home,
		fr: fr.not_found_go_home,
		hi: hi.not_found_go_home,
		id: id.not_found_go_home,
		"pt-PT": pt_PT.not_found_go_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = (params = {}, options = {}) => {
	return {
		ar: ar.error_title,
		de: de.error_title,
		en: en.error_title,
		es: es.error_title,
		fa: fa.error_title,
		fr: fr.error_title,
		hi: hi.error_title,
		id: id.error_title,
		"pt-PT": pt_PT.error_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = (params = {}, options = {}) => {
	return {
		ar: ar.error_description,
		de: de.error_description,
		en: en.error_description,
		es: es.error_description,
		fa: fa.error_description,
		fr: fr.error_description,
		hi: hi.error_description,
		id: id.error_description,
		"pt-PT": pt_PT.error_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = (params = {}, options = {}) => {
	return {
		ar: ar.error_go_home,
		de: de.error_go_home,
		en: en.error_go_home,
		es: es.error_go_home,
		fa: fa.error_go_home,
		fr: fr.error_go_home,
		hi: hi.error_go_home,
		id: id.error_go_home,
		"pt-PT": pt_PT.error_go_home
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = (params = {}, options = {}) => {
	return {
		ar: ar.no_subtitles_title,
		de: de.no_subtitles_title,
		en: en.no_subtitles_title,
		es: es.no_subtitles_title,
		fa: fa.no_subtitles_title,
		fr: fr.no_subtitles_title,
		hi: hi.no_subtitles_title,
		id: id.no_subtitles_title,
		"pt-PT": pt_PT.no_subtitles_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = (params = {}, options = {}) => {
	return {
		ar: ar.no_subtitles_upload_one,
		de: de.no_subtitles_upload_one,
		en: en.no_subtitles_upload_one,
		es: es.no_subtitles_upload_one,
		fa: fa.no_subtitles_upload_one,
		fr: fr.no_subtitles_upload_one,
		hi: hi.no_subtitles_upload_one,
		id: id.no_subtitles_upload_one,
		"pt-PT": pt_PT.no_subtitles_upload_one
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = (params = {}, options = {}) => {
	return {
		ar: ar.no_subtitles_go_upload,
		de: de.no_subtitles_go_upload,
		en: en.no_subtitles_go_upload,
		es: es.no_subtitles_go_upload,
		fa: fa.no_subtitles_go_upload,
		fr: fr.no_subtitles_go_upload,
		hi: hi.no_subtitles_go_upload,
		id: id.no_subtitles_go_upload,
		"pt-PT": pt_PT.no_subtitles_go_upload
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.api_page_title,
		de: de.api_page_title,
		en: en.api_page_title,
		es: es.api_page_title,
		fa: fa.api_page_title,
		fr: fr.api_page_title,
		hi: hi.api_page_title,
		id: id.api_page_title,
		"pt-PT": pt_PT.api_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.api_page_description,
		de: de.api_page_description,
		en: en.api_page_description,
		es: es.api_page_description,
		fa: fa.api_page_description,
		fr: fr.api_page_description,
		hi: hi.api_page_description,
		id: id.api_page_description,
		"pt-PT": pt_PT.api_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.api_doc_page_title,
		de: de.api_doc_page_title,
		en: en.api_doc_page_title,
		es: es.api_doc_page_title,
		fa: fa.api_doc_page_title,
		fr: fr.api_doc_page_title,
		hi: hi.api_doc_page_title,
		id: id.api_doc_page_title,
		"pt-PT": pt_PT.api_doc_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.api_doc_page_description,
		de: de.api_doc_page_description,
		en: en.api_doc_page_description,
		es: es.api_doc_page_description,
		fa: fa.api_doc_page_description,
		fr: fr.api_doc_page_description,
		hi: hi.api_doc_page_description,
		id: id.api_doc_page_description,
		"pt-PT": pt_PT.api_doc_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = (params = {}, options = {}) => {
	return {
		ar: ar.plugin_page_title,
		de: de.plugin_page_title,
		en: en.plugin_page_title,
		es: es.plugin_page_title,
		fa: fa.plugin_page_title,
		fr: fr.plugin_page_title,
		hi: hi.plugin_page_title,
		id: id.plugin_page_title,
		"pt-PT": pt_PT.plugin_page_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.plugin_page_description,
		de: de.plugin_page_description,
		en: en.plugin_page_description,
		es: es.plugin_page_description,
		fa: fa.plugin_page_description,
		fr: fr.plugin_page_description,
		hi: hi.plugin_page_description,
		id: id.plugin_page_description,
		"pt-PT": pt_PT.plugin_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = (params = {}, options = {}) => {
	return {
		ar: ar.search_recent_search,
		de: de.search_recent_search,
		en: en.search_recent_search,
		es: es.search_recent_search,
		fa: fa.search_recent_search,
		fr: fr.search_recent_search,
		hi: hi.search_recent_search,
		id: id.search_recent_search,
		"pt-PT": pt_PT.search_recent_search
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = (params = {}, options = {}) => {
	return {
		ar: ar.search_clear_recent_search,
		de: de.search_clear_recent_search,
		en: en.search_clear_recent_search,
		es: es.search_clear_recent_search,
		fa: fa.search_clear_recent_search,
		fr: fr.search_clear_recent_search,
		hi: hi.search_clear_recent_search,
		id: id.search_clear_recent_search,
		"pt-PT": pt_PT.search_clear_recent_search
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = (params = {}, options = {}) => {
	return {
		ar: ar.search_trends,
		de: de.search_trends,
		en: en.search_trends,
		es: es.search_trends,
		fa: fa.search_trends,
		fr: fr.search_trends,
		hi: hi.search_trends,
		id: id.search_trends,
		"pt-PT": pt_PT.search_trends
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = (params = {}, options = {}) => {
	return {
		ar: ar.bio_min_length,
		de: de.bio_min_length,
		en: en.bio_min_length,
		es: es.bio_min_length,
		fa: fa.bio_min_length,
		fr: fr.bio_min_length,
		hi: hi.bio_min_length,
		id: id.bio_min_length,
		"pt-PT": pt_PT.bio_min_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = (params = {}, options = {}) => {
	return {
		ar: ar.bio_max_length,
		de: de.bio_max_length,
		en: en.bio_max_length,
		es: es.bio_max_length,
		fa: fa.bio_max_length,
		fr: fr.bio_max_length,
		hi: hi.bio_max_length,
		id: id.bio_max_length,
		"pt-PT": pt_PT.bio_max_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = (params = {}, options = {}) => {
	return {
		ar: ar.comment_min_length,
		de: de.comment_min_length,
		en: en.comment_min_length,
		es: es.comment_min_length,
		fa: fa.comment_min_length,
		fr: fr.comment_min_length,
		hi: hi.comment_min_length,
		id: id.comment_min_length,
		"pt-PT": pt_PT.comment_min_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = (params = {}, options = {}) => {
	return {
		ar: ar.comment_max_length,
		de: de.comment_max_length,
		en: en.comment_max_length,
		es: es.comment_max_length,
		fa: fa.comment_max_length,
		fr: fr.comment_max_length,
		hi: hi.comment_max_length,
		id: id.comment_max_length,
		"pt-PT": pt_PT.comment_max_length
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = (params = {}, options = {}) => {
	return {
		ar: ar.login_required,
		de: de.login_required,
		en: en.login_required,
		es: es.login_required,
		fa: fa.login_required,
		fr: fr.login_required,
		hi: hi.login_required,
		id: id.login_required,
		"pt-PT": pt_PT.login_required
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = (params = {}, options = {}) => {
	return {
		ar: ar.enter_comment,
		de: de.enter_comment,
		en: en.enter_comment,
		es: es.enter_comment,
		fa: fa.enter_comment,
		fr: fr.enter_comment,
		hi: hi.enter_comment,
		id: id.enter_comment,
		"pt-PT": pt_PT.enter_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = (params = {}, options = {}) => {
	return {
		ar: ar.comment_sent,
		de: de.comment_sent,
		en: en.comment_sent,
		es: es.comment_sent,
		fa: fa.comment_sent,
		fr: fr.comment_sent,
		hi: hi.comment_sent,
		id: id.comment_sent,
		"pt-PT": pt_PT.comment_sent
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = (params = {}, options = {}) => {
	return {
		ar: ar.comment_submitted,
		de: de.comment_submitted,
		en: en.comment_submitted,
		es: es.comment_submitted,
		fa: fa.comment_submitted,
		fr: fr.comment_submitted,
		hi: hi.comment_submitted,
		id: id.comment_submitted,
		"pt-PT": pt_PT.comment_submitted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = (params = {}, options = {}) => {
	return {
		ar: ar.comment_submitted_description,
		de: de.comment_submitted_description,
		en: en.comment_submitted_description,
		es: es.comment_submitted_description,
		fa: fa.comment_submitted_description,
		fr: fr.comment_submitted_description,
		hi: hi.comment_submitted_description,
		id: id.comment_submitted_description,
		"pt-PT": pt_PT.comment_submitted_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = (params = {}, options = {}) => {
	return {
		ar: ar.comment_placeholder,
		de: de.comment_placeholder,
		en: en.comment_placeholder,
		es: es.comment_placeholder,
		fa: fa.comment_placeholder,
		fr: fr.comment_placeholder,
		hi: hi.comment_placeholder,
		id: id.comment_placeholder,
		"pt-PT": pt_PT.comment_placeholder
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = (params = {}, options = {}) => {
	return {
		ar: ar.submit_comment,
		de: de.submit_comment,
		en: en.submit_comment,
		es: es.submit_comment,
		fa: fa.submit_comment,
		fr: fr.submit_comment,
		hi: hi.submit_comment,
		id: id.submit_comment,
		"pt-PT": pt_PT.submit_comment
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = (params = {}, options = {}) => {
	return {
		ar: ar.rating,
		de: de.rating,
		en: en.rating,
		es: es.rating,
		fa: fa.rating,
		fr: fr.rating,
		hi: hi.rating,
		id: id.rating,
		"pt-PT": pt_PT.rating
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_uploaded,
		de: de.panel_subtitles_uploaded,
		en: en.panel_subtitles_uploaded,
		es: es.panel_subtitles_uploaded,
		fa: fa.panel_subtitles_uploaded,
		fr: fr.panel_subtitles_uploaded,
		hi: hi.panel_subtitles_uploaded,
		id: id.panel_subtitles_uploaded,
		"pt-PT": pt_PT.panel_subtitles_uploaded
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_published,
		de: de.panel_subtitles_published,
		en: en.panel_subtitles_published,
		es: es.panel_subtitles_published,
		fa: fa.panel_subtitles_published,
		fr: fr.panel_subtitles_published,
		hi: hi.panel_subtitles_published,
		id: id.panel_subtitles_published,
		"pt-PT": pt_PT.panel_subtitles_published
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_my_subtitles,
		de: de.panel_subtitles_my_subtitles,
		en: en.panel_subtitles_my_subtitles,
		es: es.panel_subtitles_my_subtitles,
		fa: fa.panel_subtitles_my_subtitles,
		fr: fr.panel_subtitles_my_subtitles,
		hi: hi.panel_subtitles_my_subtitles,
		id: id.panel_subtitles_my_subtitles,
		"pt-PT": pt_PT.panel_subtitles_my_subtitles
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_uploaded_description,
		de: de.panel_subtitles_uploaded_description,
		en: en.panel_subtitles_uploaded_description,
		es: es.panel_subtitles_uploaded_description,
		fa: fa.panel_subtitles_uploaded_description,
		fr: fr.panel_subtitles_uploaded_description,
		hi: hi.panel_subtitles_uploaded_description,
		id: id.panel_subtitles_uploaded_description,
		"pt-PT": pt_PT.panel_subtitles_uploaded_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_delete_message,
		de: de.panel_subtitles_delete_message,
		en: en.panel_subtitles_delete_message,
		es: es.panel_subtitles_delete_message,
		fa: fa.panel_subtitles_delete_message,
		fr: fr.panel_subtitles_delete_message,
		hi: hi.panel_subtitles_delete_message,
		id: id.panel_subtitles_delete_message,
		"pt-PT": pt_PT.panel_subtitles_delete_message
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = (params = {}, options = {}) => {
	return {
		ar: ar.panel_subtitles_deleted,
		de: de.panel_subtitles_deleted,
		en: en.panel_subtitles_deleted,
		es: es.panel_subtitles_deleted,
		fa: fa.panel_subtitles_deleted,
		fr: fr.panel_subtitles_deleted,
		hi: hi.panel_subtitles_deleted,
		id: id.panel_subtitles_deleted,
		"pt-PT": pt_PT.panel_subtitles_deleted
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = (params = {}, options = {}) => {
	return {
		ar: ar.uploaded_subtitles_id,
		de: de.uploaded_subtitles_id,
		en: en.uploaded_subtitles_id,
		es: es.uploaded_subtitles_id,
		fa: fa.uploaded_subtitles_id,
		fr: fr.uploaded_subtitles_id,
		hi: hi.uploaded_subtitles_id,
		id: id.uploaded_subtitles_id,
		"pt-PT": pt_PT.uploaded_subtitles_id
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = (params = {}, options = {}) => {
	return {
		ar: ar.uploaded_subtitles_name,
		de: de.uploaded_subtitles_name,
		en: en.uploaded_subtitles_name,
		es: es.uploaded_subtitles_name,
		fa: fa.uploaded_subtitles_name,
		fr: fr.uploaded_subtitles_name,
		hi: hi.uploaded_subtitles_name,
		id: id.uploaded_subtitles_name,
		"pt-PT": pt_PT.uploaded_subtitles_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = (params = {}, options = {}) => {
	return {
		ar: ar.uploaded_subtitles_language,
		de: de.uploaded_subtitles_language,
		en: en.uploaded_subtitles_language,
		es: es.uploaded_subtitles_language,
		fa: fa.uploaded_subtitles_language,
		fr: fr.uploaded_subtitles_language,
		hi: hi.uploaded_subtitles_language,
		id: id.uploaded_subtitles_language,
		"pt-PT": pt_PT.uploaded_subtitles_language
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = (params = {}, options = {}) => {
	return {
		ar: ar.uploaded_subtitles_date,
		de: de.uploaded_subtitles_date,
		en: en.uploaded_subtitles_date,
		es: es.uploaded_subtitles_date,
		fa: fa.uploaded_subtitles_date,
		fr: fr.uploaded_subtitles_date,
		hi: hi.uploaded_subtitles_date,
		id: id.uploaded_subtitles_date,
		"pt-PT": pt_PT.uploaded_subtitles_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = (params = {}, options = {}) => {
	return {
		ar: ar.uploaded_subtitles_status,
		de: de.uploaded_subtitles_status,
		en: en.uploaded_subtitles_status,
		es: es.uploaded_subtitles_status,
		fa: fa.uploaded_subtitles_status,
		fr: fr.uploaded_subtitles_status,
		hi: hi.uploaded_subtitles_status,
		id: id.uploaded_subtitles_status,
		"pt-PT": pt_PT.uploaded_subtitles_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = (params = {}, options = {}) => {
	return {
		ar: ar.upload_page_action,
		de: de.upload_page_action,
		en: en.upload_page_action,
		es: es.upload_page_action,
		fa: fa.upload_page_action,
		fr: fr.upload_page_action,
		hi: hi.upload_page_action,
		id: id.upload_page_action,
		"pt-PT": pt_PT.upload_page_action
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = (params = {}, options = {}) => {
	return {
		ar: ar.upload_status_pending,
		de: de.upload_status_pending,
		en: en.upload_status_pending,
		es: es.upload_status_pending,
		fa: fa.upload_status_pending,
		fr: fr.upload_status_pending,
		hi: hi.upload_status_pending,
		id: id.upload_status_pending,
		"pt-PT": pt_PT.upload_status_pending
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = (params = {}, options = {}) => {
	return {
		ar: ar.upload_status_published,
		de: de.upload_status_published,
		en: en.upload_status_published,
		es: es.upload_status_published,
		fa: fa.upload_status_published,
		fr: fr.upload_status_published,
		hi: hi.upload_status_published,
		id: id.upload_status_published,
		"pt-PT": pt_PT.upload_status_published
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = (params = {}, options = {}) => {
	return {
		ar: ar.upload_status_rejected,
		de: de.upload_status_rejected,
		en: en.upload_status_rejected,
		es: es.upload_status_rejected,
		fa: fa.upload_status_rejected,
		fr: fr.upload_status_rejected,
		hi: hi.upload_status_rejected,
		id: id.upload_status_rejected,
		"pt-PT": pt_PT.upload_status_rejected
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = (params = {}, options = {}) => {
	return {
		ar: ar.upload_subtitle_delete,
		de: de.upload_subtitle_delete,
		en: en.upload_subtitle_delete,
		es: es.upload_subtitle_delete,
		fa: fa.upload_subtitle_delete,
		fr: fr.upload_subtitle_delete,
		hi: hi.upload_subtitle_delete,
		id: id.upload_subtitle_delete,
		"pt-PT": pt_PT.upload_subtitle_delete
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = (params = {}, options = {}) => {
	return {
		ar: ar.upload_subtitle_edit,
		de: de.upload_subtitle_edit,
		en: en.upload_subtitle_edit,
		es: es.upload_subtitle_edit,
		fa: fa.upload_subtitle_edit,
		fr: fr.upload_subtitle_edit,
		hi: hi.upload_subtitle_edit,
		id: id.upload_subtitle_edit,
		"pt-PT": pt_PT.upload_subtitle_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = (params = {}, options = {}) => {
	return {
		ar: ar.upload_subtitle_re_send,
		de: de.upload_subtitle_re_send,
		en: en.upload_subtitle_re_send,
		es: es.upload_subtitle_re_send,
		fa: fa.upload_subtitle_re_send,
		fr: fr.upload_subtitle_re_send,
		hi: hi.upload_subtitle_re_send,
		id: id.upload_subtitle_re_send,
		"pt-PT": pt_PT.upload_subtitle_re_send
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_send_telegram,
		de: de.published_subtitles_send_telegram,
		en: en.published_subtitles_send_telegram,
		es: es.published_subtitles_send_telegram,
		fa: fa.published_subtitles_send_telegram,
		fr: fr.published_subtitles_send_telegram,
		hi: hi.published_subtitles_send_telegram,
		id: id.published_subtitles_send_telegram,
		"pt-PT": pt_PT.published_subtitles_send_telegram
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_movie,
		de: de.published_subtitles_movie,
		en: en.published_subtitles_movie,
		es: es.published_subtitles_movie,
		fa: fa.published_subtitles_movie,
		fr: fr.published_subtitles_movie,
		hi: hi.published_subtitles_movie,
		id: id.published_subtitles_movie,
		"pt-PT": pt_PT.published_subtitles_movie
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_name,
		de: de.published_subtitles_name,
		en: en.published_subtitles_name,
		es: es.published_subtitles_name,
		fa: fa.published_subtitles_name,
		fr: fr.published_subtitles_name,
		hi: hi.published_subtitles_name,
		id: id.published_subtitles_name,
		"pt-PT": pt_PT.published_subtitles_name
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_lang,
		de: de.published_subtitles_lang,
		en: en.published_subtitles_lang,
		es: es.published_subtitles_lang,
		fa: fa.published_subtitles_lang,
		fr: fr.published_subtitles_lang,
		hi: hi.published_subtitles_lang,
		id: id.published_subtitles_lang,
		"pt-PT": pt_PT.published_subtitles_lang
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_hi,
		de: de.published_subtitles_hi,
		en: en.published_subtitles_hi,
		es: es.published_subtitles_hi,
		fa: fa.published_subtitles_hi,
		fr: fr.published_subtitles_hi,
		hi: hi.published_subtitles_hi,
		id: id.published_subtitles_hi,
		"pt-PT": pt_PT.published_subtitles_hi
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_date,
		de: de.published_subtitles_date,
		en: en.published_subtitles_date,
		es: es.published_subtitles_date,
		fa: fa.published_subtitles_date,
		fr: fr.published_subtitles_date,
		hi: hi.published_subtitles_date,
		id: id.published_subtitles_date,
		"pt-PT": pt_PT.published_subtitles_date
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_status,
		de: de.published_subtitles_status,
		en: en.published_subtitles_status,
		es: es.published_subtitles_status,
		fa: fa.published_subtitles_status,
		fr: fr.published_subtitles_status,
		hi: hi.published_subtitles_status,
		id: id.published_subtitles_status,
		"pt-PT": pt_PT.published_subtitles_status
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_download,
		de: de.published_subtitles_download,
		en: en.published_subtitles_download,
		es: es.published_subtitles_download,
		fa: fa.published_subtitles_download,
		fr: fr.published_subtitles_download,
		hi: hi.published_subtitles_download,
		id: id.published_subtitles_download,
		"pt-PT": pt_PT.published_subtitles_download
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_action,
		de: de.published_subtitles_action,
		en: en.published_subtitles_action,
		es: es.published_subtitles_action,
		fa: fa.published_subtitles_action,
		fr: fr.published_subtitles_action,
		hi: hi.published_subtitles_action,
		id: id.published_subtitles_action,
		"pt-PT": pt_PT.published_subtitles_action
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = (params = {}, options = {}) => {
	return {
		ar: ar.published_subtitles_send_to_ch,
		de: de.published_subtitles_send_to_ch,
		en: en.published_subtitles_send_to_ch,
		es: es.published_subtitles_send_to_ch,
		fa: fa.published_subtitles_send_to_ch,
		fr: fr.published_subtitles_send_to_ch,
		hi: hi.published_subtitles_send_to_ch,
		id: id.published_subtitles_send_to_ch,
		"pt-PT": pt_PT.published_subtitles_send_to_ch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params , options = {}) => {
	return {
		ar: ar.update_subtitle_page_title,
		de: de.update_subtitle_page_title,
		en: en.update_subtitle_page_title,
		es: es.update_subtitle_page_title,
		fa: fa.update_subtitle_page_title,
		fr: fr.update_subtitle_page_title,
		hi: hi.update_subtitle_page_title,
		id: id.update_subtitle_page_title,
		"pt-PT": pt_PT.update_subtitle_page_title
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_submit = (params = {}, options = {}) => {
	return {
		ar: ar.update_subtitle_page_submit,
		de: de.update_subtitle_page_submit,
		en: en.update_subtitle_page_submit,
		es: es.update_subtitle_page_submit,
		fa: fa.update_subtitle_page_submit,
		fr: fr.update_subtitle_page_submit,
		hi: hi.update_subtitle_page_submit,
		id: id.update_subtitle_page_submit,
		"pt-PT": pt_PT.update_subtitle_page_submit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_my_public_page,
		de: de.quick_items_my_public_page,
		en: en.quick_items_my_public_page,
		es: es.quick_items_my_public_page,
		fa: fa.quick_items_my_public_page,
		fr: fr.quick_items_my_public_page,
		hi: hi.quick_items_my_public_page,
		id: id.quick_items_my_public_page,
		"pt-PT": pt_PT.quick_items_my_public_page
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = (params = {}, options = {}) => {
	return {
		ar: ar.quick_items_my_public_page_description,
		de: de.quick_items_my_public_page_description,
		en: en.quick_items_my_public_page_description,
		es: es.quick_items_my_public_page_description,
		fa: fa.quick_items_my_public_page_description,
		fr: fr.quick_items_my_public_page_description,
		hi: hi.quick_items_my_public_page_description,
		id: id.quick_items_my_public_page_description,
		"pt-PT": pt_PT.quick_items_my_public_page_description
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_all = (params = {}, options = {}) => {
	return {
		ar: ar.search_setting_all,
		de: de.search_setting_all,
		en: en.search_setting_all,
		es: es.search_setting_all,
		fa: fa.search_setting_all,
		fr: fr.search_setting_all,
		hi: hi.search_setting_all,
		id: id.search_setting_all,
		"pt-PT": pt_PT.search_setting_all
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "fa" | "ar" | "de" | "es" | "hi" | "id" | "pt-PT" | "fr" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = (params = {}, options = {}) => {
	return {
		ar: ar.register_page_password_confirm,
		de: de.register_page_password_confirm,
		en: en.register_page_password_confirm,
		es: es.register_page_password_confirm,
		fa: fa.register_page_password_confirm,
		fr: fr.register_page_password_confirm,
		hi: hi.register_page_password_confirm,
		id: id.register_page_password_confirm,
		"pt-PT": pt_PT.register_page_password_confirm
	}[options.languageTag ?? languageTag()]()
}

