/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_title = () => `Subdl: Download Free Subtitles for Movies & TV Shows | 100+ Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_description = () => `Download free subtitles for movies and TV shows in multiple languages at Subdl.com. Find and get subtitles for entire series seasons from various sources instantly.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const website_keywords = () => `subtitle download, free subtitles, movie subtitles, TV show subtitles, multilingual subtitles, subtitle search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const index_title = () => `Find perfect subtitles in any language for all your favorite films and shows!`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_title = (params) => `Search "${params.query}" Subtitles - SUBDL`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_description = (params) => `Search ${params.query} Subtitles in English, Arabic, Indonesian, French, German, Spanish, and more Instantly.`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_keywords = (params) => `${params.query} subtitles download, ${params.query} subtitles, Download ${params.query} subs, ${params.query} BluRay subtitles, ${params.query} HDTV subtitles, Free ${params.query} subtitle download, ${params.query} closed captions, Official ${params.query} subtitles, ${params.query} subtitle file SRT, Sync ${params.query} subtitles, ${params.query} subtitle translation, High-quality ${params.query} subtitles, ${params.query} subtitle download for mobile, ${params.query} subtitles for hearing impaired, Latest ${params.query} subtitles update`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title = (params) => `${params.movieName} (${params.movieYear}) Subtitles - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description = (params) => `Download subtitles for ${params.movieName} (${params.movieYear}) Subtitles in English, Arabic, Indonesian, French, German, Spanish, and more Instantly.`


/**
 * @param {{ movieName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords = (params) => `${params.movieName} subtitles download, ${params.movieName} subtitles, Download ${params.movieName} subs, ${params.movieName} BluRay subtitles, ${params.movieName} HDTV subtitles, Free ${params.movieName} subtitle download, ${params.movieName} closed captions, Official ${params.movieName} subtitles, ${params.movieName} subtitle file SRT, Sync ${params.movieName} subtitles, ${params.movieName} subtitle translation, High-quality ${params.movieName} subtitles, ${params.movieName} subtitle download for mobile, ${params.movieName} subtitles for hearing impaired, Latest ${params.movieName} subtitles update`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_title_with_lang = (params) => `${params.movieName} (${params.movieYear}) ${params.lang} Subtitles - SUBDL`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_description_with_lang = (params) => `Download subtitles for ${params.movieName} (${params.movieYear}) Subtitles in ${params.lang} Instantly.`


/**
 * @param {{ movieName: NonNullable<unknown>, lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const film_page_keywords_with_lang = (params) => `${params.movieName} subtitles download, ${params.movieName} ${params.lang} subtitles, Download ${params.movieName} subs ${params.lang}, ${params.movieName} BluRay subtitles, ${params.movieName} HDTV subtitles, Free ${params.movieName} subtitle download, ${params.movieName} closed captions ${params.lang}, Official ${params.movieName} subtitles, ${params.movieName} subtitle file SRT, Sync ${params.movieName} subtitles ${params.lang}, ${params.movieName} subtitle translation, High-quality ${params.movieName} subtitles, ${params.movieName} subtitle download for mobile, ${params.movieName} subtitles for hearing impaired, Latest ${params.movieName} subtitles update`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_home = () => `Home`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_latest = () => `Latest`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_trends = () => `Trends`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_plugins = () => `Plugins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_blog = () => `Blog`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_community = () => `Community`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_search = () => `Search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_account = () => `Account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_logout = () => `Logout`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_join = () => `Join`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const menu_menu = () => `Menu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_placeholder = () => `Search Movies and TV shows or IMDB ID`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_no_results = () => `No results found`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_results = (params) => `Matches (${params.count} results)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_settings = () => `Search Setting`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_movie = () => `Movies Only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_tv = () => `TV Shows Only`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_selected = () => `Filter Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_has_selected_language = () => `Languages Selected`


/**
 * @param {{ query: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_other_results = (params) => `See all results for "${params.query}"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_title = () => `Search Results`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_no_results = () => `No results found`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_page_list_results = (params) => `Matches (${params.count} results)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_ads = () => `Advertise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const footer_menu_api_doc = () => `API Doc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_title = () => `Site Settings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_dark_theme = () => `Dark Theme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_light_theme = () => `Light Theme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_site_language = () => `Site Language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const setting_menu_filter_languages = () => `Filter Languages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_title = () => `Title`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_quality = () => `Quality`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_author = () => `Author`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_releases = () => `Releases`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_rate = () => `Rate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_comment = () => `Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_n_id = () => `n_id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_slug = () => `slug`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitles_table_link = () => `link`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loading = () => `Loading...`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title = (params) => `Download ${params.lang} Subtitle for ${params.movieName} (${params.movieYear})`


/**
 * @param {{ lang: NonNullable<unknown>, movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season = (params) => `Download ${params.lang} Subtitles for ${params.movieName} (${params.movieYear}) - ${params.seasonName}`


/**
 * @param {{ movieName: NonNullable<unknown>, movieYear: NonNullable<unknown>, seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_title_season_number = (params) => `Subtitles for ${params.movieName} (${params.movieYear}) - ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_release_info = () => `Release info`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_commentary_by = () => `A Commentary by`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_tags = () => `Tags`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comments = () => `Comments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_download = () => `Download`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_sup_title = (params) => `Download ${params.language} Subtitle`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_main_download_button = (params) => `Download ${params.language} Subtitle`


/**
 * @param {{ language: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_mobile_button = (params) => `Download ${params.language} Subtitle for Mobile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_seasons = () => `Seasons`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_season = (params) => `Season ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_subtitle = () => `Rate Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate_and_comment = () => `Rate and comment on this subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_comment = () => `Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_send = () => `Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_close = () => `Close`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_published_on = () => `Published on`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_rate = () => `Rate`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_hearing_impaired = () => `Hearing Impaired`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const subtitle_page_submit_comment = () => `Submit Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_sup = () => `Subdl Author / Translator`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating = () => `Rating`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comments = () => `Comments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_latest_subtitles = () => `Latest Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_pinned_subtitles = () => `Pinned Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_more_subtitles = () => `More Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_subtitles = () => `Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_download = () => `Download`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_hearing_impaired = () => `Hearing Impaired`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_submit_comment = () => `Submit Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_page = () => `page`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_comment_sup = () => `From Jan 1st 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const author_page_rating_sup = () => `Based on user ratings`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next = () => `Next`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const previous = () => `Previous`


/**
 * @param {{ lang: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_title = (params) => `Latest ${params.lang} Subtitles - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_subtitles = () => `Latest Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_movie = () => `Movie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_author = () => `Author`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_downloads = () => `Downloads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_table_time = () => `Time`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_page_caption = () => `A list of list of your recent subtitles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const latest_seasons = () => `Seasons`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav = () => `User Nav`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_dashboard = () => `Dashboard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_upload = () => `Upload subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_subtitles = () => `My Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_profile = () => `Profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const user_nav_logout = () => `Logout`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_dashboard = () => `Dashboard`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload = () => `Upload Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_upload_description = () => `Upload your subtitles in one-click.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles = () => `My Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_subtitles_description = () => `Manage your subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_api_description = () => `Manage and get API key`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile = () => `Profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_profile_description = () => `Update your profile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_title = () => `Upload Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_src_type = () => `Find Movie from`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_choose_movie_tv = () => `Choose Movie/TV`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_drop_region = () => `Drop Subtitle Files Here Or Click To Select`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_files = () => `Files`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_releases = () => `Releases`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_add = () => `Add`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_remove = () => `Remove`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_language = () => `Language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_language = () => `Select language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_release_type = () => `Release type (Quality)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_quality = () => `Select a Quality`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_production_type = () => `Production Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_select_production_type = () => `Select a Production Type`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_remove_movie = () => `Remove`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_transcript = () => `Transcript (By listening)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_translated = () => `Translated a subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_improved = () => `Improved a subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_retail = () => `From retail`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_prod_type_machine = () => `Machine translated`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_season = () => `Season`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_episode = () => `Episode`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_full_season = () => `Full Season`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_comment = () => `Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_hearing_impaired = () => `Hearing Impaired`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps = () => `FPS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_submit = () => `Upload`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_fps_default = () => `don't know`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle_page_title = () => `Edit Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_download = () => `Quick Download`


/**
 * @param {{ seasonNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const season = (params) => `Season ${params.seasonNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_title = () => `Sign Up - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_description = () => `Join SUBDL and start downloading and uploading subtitles in your favorite languages.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_title = () => `Sign Up`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_form_subtitle = () => `Enter your information to create an account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_email = () => `Email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_username = () => `Username`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password = () => `Password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_country = () => `Country`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_create_account = () => `Create an account`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_up_with_google = () => `Sign up with Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_have_an_account = () => `Already have an account?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_sign_in = () => `Sign in`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_title = () => `Login - SUBDL`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_description = () => `Login with your email and password`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_title = () => `Login`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_form_subtitle = () => `Enter your information to login`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_button = () => `Login`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_with_google = () => `Login with Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_have_an_account = () => `Don't have an account?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_sign_up = () => `Sign up`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_page_forget_password = () => `Forgot your password?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const page = () => `Page`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_title = () => `Logged Out`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout_description = () => `You have been logged out successfully.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter = () => `Search for name, id, comment...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_filter_description = () => `Enter the text, then press Enter or click the icon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_title = () => `My Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mysubtitles_page_description = () => `Manage my subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const add_subtitle = () => `Add Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const edit_subtitle = () => `Edit Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_title = () => `Page Not Found`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_description = () => `Page Not Found`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const not_found_go_home = () => `Go Home`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_title = () => `Something went wrong!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_description = () => `Something went wrong!`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_go_home = () => `Go Home`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_title = () => `No Subtitles Found`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_upload_one = () => `You can upload subtitle for this page if have one.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const no_subtitles_go_upload = () => `Upload Subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_title = () => `API`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_page_description = () => `Manage and get API key`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_title = () => `API Doc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const api_doc_page_description = () => `Subtitles Search API Documentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_title = () => `Plugins`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plugin_page_description = () => `Plugins for Subdl.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_recent_search = () => `Recent Search`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_clear_recent_search = () => `Clear`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_trends = () => `Trends`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_min_length = () => `Bio must be at least 10 characters.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bio_max_length = () => `Bio must not be longer than 500 characters.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_min_length = () => `Comment must be at least 5 characters.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_max_length = () => `Comment must not be longer than 500 characters.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_required = () => `Please login first`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enter_comment = () => `Please enter comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_sent = () => `Comment sent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted = () => `You comment submitted`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_submitted_description = () => `Your comment has been submitted`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const comment_placeholder = () => `Leave a comment about this subtitle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const submit_comment = () => `Submit Comment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rating = () => `Rating`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded = () => `Uploaded`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_published = () => `Published`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_my_subtitles = () => `My Sc Subtitles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_uploaded_description = () => `List of pending approved subtitles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_delete_message = () => `Are you sure you want to delete this subtitle ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const panel_subtitles_deleted = () => `Subtitle deleted successfully.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_id = () => `Id`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_language = () => `Language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const uploaded_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_page_action = () => `Action`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_pending = () => `Pending`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_published = () => `Published`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_status_rejected = () => `Rejected`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_delete = () => `Delete`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_edit = () => `Edit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_subtitle_re_send = () => `Re-Send`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_telegram = () => `Are you sure you want to send bot message to telegram?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_movie = () => `Movie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_name = () => `Name`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_lang = () => `Language`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_hi = () => `HI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_status = () => `Status`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_download = () => `Download`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_action = () => `Actions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const published_subtitles_send_to_ch = () => `Send to Channel`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_title = (params) => `Edit Subtitle ${params.name}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const update_subtitle_page_submit = () => `Update`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page = () => `My Public Page`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_items_my_public_page_description = () => `See your public page`


/**
 * Failed to resolve message search_setting_all for languageTag "en". 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const search_setting_all = () => "search_setting_all"


/**
 * Failed to resolve message register_page_password_confirm for languageTag "en". 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const register_page_password_confirm = () => "register_page_password_confirm"
